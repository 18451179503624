import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { handlePaginationState } from "../../../store/products/productsSlice";
import mastersApis from "../../../store/masters/mastersApis";

const mapStateToProps = (state) => {
    return {
        pagination: state.products.supplierProductsPagination,
    };
};

const mapDispatch = {
    handlePaginationState: handlePaginationState,
    getProductTypesList: mastersApis.endpoints.getProductTypesList.initiate,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;