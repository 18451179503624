import { Grid, InputAdornment, TextField } from "@mui/material"
import { debounce } from "../../helpers/utils";
import { SearchOutlined } from "@mui/icons-material";

export const SearchRecords = ({ handlePagination, pagination, placeholder }) => {

    const handleSearch = (val) => {
        // let keywords = val.toLowerCase();
        handlePagination({
            pageNo: 0,
            limit: 10,
            keywords: val
        })
    }

    const processChange = debounce((e) => handleSearch(e));

    return (
        <Grid item textAlign={"right"}>
            <TextField
                size="small"
                id="outlined-search"
                placeholder={placeholder}
                type="search"
                style={{ minWidth: 300 }}
                onChange={(e) => processChange(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlined />
                        </InputAdornment>
                    ),
                }}
            />
        </Grid>
    )
}