import React, { Fragment } from "react";
import { Formik } from "formik";
import { ProductTypesValidation } from "./ProductTypesValidation";
import { Drawer, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";

import { setSnackBar } from "../../../store/common/commonSlice";
import { useAddNewProductTypesMutation, useGetCategoryDropdownQuery, useUpdateProductTypesMutation } from "../../../store/masters/mastersApis";
import BasicSelect from "../../Common/Select";
import RichTextEditor from "../../Common/editor/RichTextEditor";

export const ProductTypesAddDrawer = ({ initialValues, show, close, formType }) => {
    const dispatch = useDispatch();
    const [addNewProductTypes, { isLoading: addProductTypesLoading }] = useAddNewProductTypesMutation();
    const [updateProductTypes, { isLoading: updateProductTypesLoading }] = useUpdateProductTypesMutation();

    const { data: categoryDropdown } = useGetCategoryDropdownQuery();

    const disabled = formType === 'View' ? true : false;

    const onFormSubmit = async (values, resetForm) => {
        try {
            let payload = { ...values };

            if (formType !== "Add") {
                await updateProductTypes(payload).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Product type updated successfully",
                    severity: "success",
                }))
            } else {
                await addNewProductTypes(payload).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Product type created successfully",
                    severity: "success",
                }))
            }
            close();
            resetForm();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };


    return (
        <Formik
            enableReinitialize
            initialValues={{ ...initialValues }}
            // onSubmit={(values) => onFormSubmit(values)}
            onSubmit={(values, { resetForm }) => {
                onFormSubmit(values, resetForm);
            }}
            validationSchema={ProductTypesValidation}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: { width: { xs: '100%', md: formType === "Content" ? '55%' : '25%', sm: formType === "Content" ? '55%' : '25%', lg: formType === "Content" ? '55%' : '25%' } },
                    }}
                    onClose={() => close()}
                >
                    <Grid container sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={2} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"} justifyContent={"space-between"}>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">{formType === "Add" ? "Create new Product Type" : "Update Product Type"}</Typography>
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={2} overflow={"auto"}>
                            <Grid container spacing={2} >
                                {
                                    formType === 'Edit' && (
                                        <Grid item xs={12} md={12}>
                                            <Input
                                                label="ID number"
                                                value={values?.id || ""}
                                                fullWidth
                                                disabled={true}
                                                sx={{ backgroundColor: "#FAFAFA" }}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                    formType === "Content"
                                        ?
                                        (
                                            <Grid item xs={12} md={12}>
                                                <RichTextEditor
                                                    defaultValue={values?.content || ""}
                                                    setValue={(val) => setFieldValue("content", val)}
                                                />
                                            </Grid>
                                        )
                                        :
                                        <Fragment>
                                            <Grid item xs={12} md={12}>
                                                <BasicSelect
                                                    name="categoryId"
                                                    label="Category"
                                                    placeholder="Select Category"
                                                    options={categoryDropdown || []}
                                                    onChange={handleChange("categoryId")}
                                                    value={values?.categoryId || ""}
                                                    error={Boolean(errors.categoryId)}
                                                    helperText={errors.categoryId}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Input
                                                    id='name'
                                                    name="name"
                                                    label="ProductTypes"
                                                    placeholder="Enter Product Type"
                                                    onChange={handleChange("name")}
                                                    value={values?.name || ""}
                                                    error={Boolean(errors.name)}
                                                    helperText={errors.name}
                                                    fullWidth
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                        </Fragment>

                                }

                            </Grid>
                        </Grid>
                        {
                            formType !== 'View'
                                ?
                                <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Button size="medium" type="submit" loading={addProductTypesLoading || updateProductTypesLoading} onClick={() => handleSubmit()}>Save Product Type</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </Drawer >
            )
            }
        </Formik >
    )
}