import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Checkbox, Chip, Drawer, FormControlLabel, Grid, InputLabel, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { setSnackBar } from "../../../store/common/commonSlice";
import { useUpdateStatusMutation, useUpdateSuppliersMutation } from "../../../store/suppliers/suppliersApis";
import { MultipleFileUploadToServer } from "../../Common/MultipleFileUploadToServer";
import StyledTextarea from "../../Common/StyledTextarea";
import Button from "../../Common/Button";
import { useUpdateProductsMutation } from "../../../store/products/productsApis";

export const ProductAddDrawer = ({ show, close, initialValues }) => {
    const dispatch = useDispatch();
    const [handleUpdate] = useUpdateProductsMutation();

    const onFormSubmit = async (values, resetForm) => {
        try {
            let payload = {
                id: values.id,
                description: values.description,
                hasImages: values.hasImages,
                images: values.images,
                productTypeId: values.productTypeId,
                supplierId: values.supplier.id,
                product_name: values.product_name
            }

            const result = await handleUpdate(payload);

            if (result) {
                dispatch(setSnackBar({
                    open: true,
                    message: "Supplier updated successfully",
                    severity: "success",
                }))
            }
            close();
            resetForm();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    const validationSchema = yup.object().shape({
        description: yup.string().required("Description is required"),
        hasImages: yup.boolean(),
        images: yup.array().of(yup.string().url().nullable()).max(5, "You can upload up to 5 images"),
    });

    return (
        <Formik
            enableReinitialize
            initialValues={{ ...initialValues }}
            // onSubmit={(values) => onFormSubmit(values)}
            onSubmit={(values, { resetForm }) => {
                onFormSubmit(values, resetForm);
            }}
            validationSchema={validationSchema}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue,
                touched
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: { width: { xs: '100%', md: '55%', sm: "55%", lg: "55%" } },
                    }}
                    onClose={() => close()}
                >
                    <Grid container sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={2} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"} justifyContent={"space-between"}>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">
                                    {values?.product_name}
                                    <Chip label={`ID: ${values?.unique_id}`} sx={{ ml: 1 }} />
                                </Typography>
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={2} overflow={"auto"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel>Product Description <span style={{ color: "red" }}>*</span> </InputLabel>
                                    <StyledTextarea
                                        name={`description`}
                                        value={values?.description || ""}
                                        onChange={(e) => setFieldValue(`description`, e.target.value)}
                                        minRows={4}
                                        fullWidth
                                        onBlur={handleBlur}
                                        error={touched?.description && Boolean(errors?.description)}
                                        helperText={touched?.description && errors?.description}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={`hasImages`}
                                                checked={values?.hasImages || false}
                                                onChange={(e) => {
                                                    setFieldValue(`hasImages`, e.target.checked);
                                                    if (!e.target.checked) setFieldValue(`images`, []);
                                                }}
                                            />
                                        }
                                        label="Do you have product Images?"
                                    />
                                </Grid>

                                {values?.hasImages && (
                                    <Grid item xs={12}>
                                        <MultipleFileUploadToServer
                                            name="images"
                                            onChange={(files) => {
                                                setFieldValue(`images`, files);
                                            }}
                                            value={values?.images || []}
                                            label="Upload Images (up to 5)"
                                            error={Boolean(errors.images)}
                                            helperText={errors.images}
                                            multiple={true}
                                            maxLength={5}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                            <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Button size="medium" type="submit" loading={false} onClick={() => handleSubmit()}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Drawer>
            )
            }
        </Formik >
    )
}