import { List } from "@mui/material";
import { SingleLevelMenu } from "./SingleLevelMenu";
import { Fragment } from "react";
import { MultiLevelMenu } from "./MultiLevelMenu";
import { drawerWidth } from "../../helpers/constants";

const MenuList = ({ modules, roles, navigate, path_url, theme }) => {
    return (
        <>
            <List sx={{ width: '100%', maxWidth: drawerWidth, bgcolor: 'background.paper', px: 2 }}>
                {modules.map((module, i) => {
                    let hasAccess = module.role.includes(roles);
                    if (!hasAccess) return false;
                    return (
                        <div key={i}>
                            {
                                module.items && module.items.length > 0
                                    ?
                                    <MultiLevelMenu
                                        module={module}
                                        navigate={navigate}
                                        path_url={path_url}
                                        theme={theme}
                                    />
                                    :
                                    <SingleLevelMenu
                                        module={module}
                                        onClick={() => navigate(module.link)}
                                        path_url={path_url}
                                        theme={theme}
                                    />
                            }
                        </div>
                    );
                })}
            </List>
        </>
    )
}

export default MenuList;