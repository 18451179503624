import React, { useEffect } from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";

import CertifiedTableComponent from "../../../components/admin/certified-manager/CertifiedTable";
import { CertifiedAddDrawer } from "../../../components/admin/certified-manager/CertifiedAddDrawer";

import { useGetCertifiedListQuery } from "../../../store/masters/mastersApis";
import { defaultPaginate } from "../../../helpers/constants";
import { AddOutlined } from "@mui/icons-material";

const CertifiedManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination } = props;
    const { isLoading, data: supplierTypes, isFetching } = useGetCertifiedListQuery(pagination);

    const handleAddCertified = () => {
        props.changeCertifiedInitialState({
            showDrawer: true,
            formType: "Add",
            initialValues: null
        })
    }

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [])

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleDrawerClose = () => {
        props.changeCertifiedInitialState({ showDrawer: false, formType: "", initialValues: null })
    }

    const handleCertifiedEdit = (data, type) => {
        let payload = {
            id: data.id,
            name: data.name,
        };
        props.changeCertifiedInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    return (
        <Stack spacing={2}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6" >Certified By <span style={{ color: "gray", fontSize: "10px", verticalAlign: "super" }}>({supplierTypes?.total || 0})</span> </Typography>
                </Grid>
                <Grid item xs={6} sx={{ alignSelf: "center" }} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <Button
                        disableElevation
                        variant="contained"
                        sx={{ borderRadius: "8px", marginLeft: 2, display: "flex", alignItems: "center", gap: 1, fontSize: "16px" }}
                        onClick={() => handleAddCertified()}
                    >
                        <AddOutlined />
                        New Certification
                    </Button>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <CertifiedTableComponent edit={(val, type) => handleCertifiedEdit(val, type)} loading={isLoading} fetching={isFetching} count={supplierTypes?.total} data={supplierTypes?.results || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>
            <CertifiedAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />
        </Stack >
    );
}

export default CertifiedManagerContainer;