import React from "react";
import { styled } from "@mui/system";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

const CustomListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
    my: 0.5,
    gap: "10px",
    borderRadius: "10px",
    position: "relative",
    "&:before": {
        content: '""',
        position: "absolute",
        top: "13.5%",
        right: "0",
        bottom: "0",
        width: "4px", // Width of the red border
        height: "70%",
        backgroundColor: selected ? "#ff0000" : "transparent", // Red color if selected
        borderRadius: "0 8px 8px 0", // Round the corners if necessary
    },
}));

export const SingleLevelMenu = ({
    module,
    onClick,
    path_url,
    theme,
    haveParent = false,
}) => {
    const isSelected =
        path_url == module.link ||
        (path_url === "/" && module.link === "/dashboard");

    return (
        <CustomListItemButton
            button="true"
            key={module.id}
            onClick={onClick}
            selected={isSelected}
            sx={{
                backgroundColor: isSelected
                    ? theme.palette.action.selected
                    : "transparent",
                ...(haveParent ? { pl: 5 } : {}),
            }}>
            {module?.logo && (
                <ListItemIcon
                    sx={{
                        minWidth: "35px",
                        ...(isSelected
                            ? {
                                  color: theme.palette.primary.main,
                              }
                            : null),
                        ...(theme.palette.mode === "dark" &&
                        module.title === "Connections"
                            ? { filter: "invert(1)" }
                            : ""),
                    }}>
                    {module.logo(isSelected)}
                </ListItemIcon>
            )}

            <ListItemText
                primary={module.title}
                sx={{
                    ...(isSelected
                        ? {
                              color: theme.palette.primary.main,
                          }
                        : null),
                }}
            />
        </CustomListItemButton>
    );
};
