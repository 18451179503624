import React, { useState } from "react";
import {
    TableCell,
    TableRow,
    Typography,
    Stack,
    Switch,
    Button,
} from "@mui/material";
import TableCommon from "../../Common/Table";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { useUpdateCertifiedMutation } from "../../../store/masters/mastersApis";
import VerticalMenu from "../../Common/VerticleAction";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import { PencilIcon } from "../../Common/Icons";
import CustomizedSwitches from "../../Common/CustomSwitch";

const CertifiedTableComponent = ({ loading, fetching = false, count, data, edit, pagination, handlePagination }) => {
    const [handleUpdate] = useUpdateCertifiedMutation();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [certifiedIdToDelete, setCertifiedIdToDelete] = useState(null);

    const handleDisable = (row) => {
        setCertifiedIdToDelete(row);
        setConfirmationOpen(true);
    };

    const handleConfirmDisable = () => {
        handleUpdate({
            id: certifiedIdToDelete?.id,
            name: certifiedIdToDelete?.name,
            disabled: !certifiedIdToDelete?.disabled
        });
        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };

    let columns = [
        {
            title: "ID No.",
            sort: false,
            width: "10%",
        },
        {
            title: "Type",
            field: "name",
            sort: true,
            width: "30%",
        },
        {
            title: "Created by & date",
            field: "createdAt",
            sort: true,
            width: "20%",
        },
        {
            title: "",
            name: "",
            sort: false,
            width: "10%",
        },
    ];

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" }, ...(row?.disabled && { opacity: 0.4 }) }}
                >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                        {row?.name}
                        {row?.disabled ? <Button sx={{ ml: 2 }} disabled size="small" variant="contained">Disabled</Button> : null}
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" fontFamily={500}>{row?.createdBy?.name}</Typography>
                        <Typography variant="caption">{handleDateTimeDefault(row?.createdAt)}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <VerticalMenu>
                            <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => edit(row, "Edit")}>
                                <PencilIcon /> Update
                            </Typography>
                            <Stack direction="row" width={"100%"} justifyContent={"space-between"} >
                                <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" >
                                    {row?.disabled ? "Enable" : "Disable"}
                                </Typography>
                                <CustomizedSwitches checked={confirmationOpen} onChange={() => handleDisable(row)} />
                            </Stack>
                        </VerticalMenu>
                    </TableCell>
                </TableRow>
            );
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={5} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <>
            <TableCommon columns={columns} tableData={renderTableData} count={count} loading={loading || fetching} pagination={pagination} handlePagination={handlePagination} />

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmDisable}
                title={certifiedIdToDelete?.disabled ? "Are you sure you want to enable selected row?" : "Are you sure you want to disable selected row?"}
                confirmButtonTitle={certifiedIdToDelete?.disabled ? "Yes, Enable" : "Yes, Disable"}
            />
        </>
    );
};

export default CertifiedTableComponent;
