import { get } from "lodash-es";

export const icategoryParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.results)
            response.results = response.results.map((row, key) => {
                return {
                    id: get(row, "id", ""),
                    name: get(row, "name", ""),
                    disabled: get(row, "disabled", ""),
                    disabledAt: get(row, "disabledAt", ""),
                    createdAt: get(row, "createdAt", ""),
                    createdBy: get(row, "createdBy", ""),
                }
            })

        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const categoryParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.results)
            response.results = response.results.map((row, key) => {
                return {
                    id: get(row, "id", ""),
                    name: get(row, "name", ""),
                    disabled: get(row, "disabled", ""),
                    priority: get(row, "priority", ""),
                    homepagedisplay: get(row, "homepagedisplay", ""),

                    disabledAt: get(row, "disabledAt", ""),
                    createdAt: get(row, "createdAt", ""),
                    createdBy: get(row, "createdBy", ""),
                    icategoryId: get(row, "icategoryId", ""),
                    icategory: get(row, "icategory", ""),
                }
            })

        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const productTypesParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.results)
            response.results = response.results.map((row, key) => {
                return {
                    id: get(row, "id", ""),
                    name: get(row, "name", ""),
                    content: get(row, "content", ""),
                    disabled: get(row, "disabled", ""),
                    disabledAt: get(row, "disabledAt", ""),
                    createdAt: get(row, "createdAt", ""),
                    createdBy: get(row, "createdBy", ""),
                    categoryId: get(row, "categoryId", ""),
                    category: get(row, "category", ""),
                    image: get(row, "image", ""),
                }
            })

        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const supplierTypesParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.results)
            response.results = response.results.map((row, key) => {
                return {
                    id: get(row, "id", ""),
                    name: get(row, "name", ""),
                    disabled: get(row, "disabled", ""),
                    disabledAt: get(row, "disabledAt", ""),
                    createdAt: get(row, "createdAt", ""),
                    createdBy: get(row, "createdBy", ""),
                }
            })

        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const certifiedParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.results)
            response.results = response.results.map((row, key) => {
                return {
                    id: get(row, "id", ""),
                    name: get(row, "name", ""),
                    disabled: get(row, "disabled", ""),
                    disabledAt: get(row, "disabledAt", ""),
                    createdAt: get(row, "createdAt", ""),
                    createdBy: get(row, "createdBy", ""),
                }
            })

        return response;
    } catch (error) {
        throw new Error(error);
    }
}