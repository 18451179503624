import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import { WarningIcon } from "../Common/Icons";
import { CloseOutlined } from "@mui/icons-material";

const ConfirmationDialog = ({ open, onClose, onConfirm, title, confirmButtonTitle, subtitle = null }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" sx={{ textAlign: "center" }}>
            <CloseOutlined onClick={onClose} sx={{ position: "absolute", right: 15, top: 15, cursor: "pointer" }} />
            <DialogContent sx={{ textAlign: "center", px: 5, py: 3 }}>
                <WarningIcon />
                <Typography variant="h6">{title}</Typography>
                {subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", paddingBottom: 3 }}>
                <Button onClick={onClose} color="primary" variant="outlined">
                    No
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained">
                    {confirmButtonTitle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
