import React, { Fragment, useState } from "react";
import { Box, Grid, Stack, TablePagination, Typography } from "@mui/material";
import TablePaginationActions from "../../Common/TablePaginationActions";
import Button from "../../Common/Button";
import { Add } from "@mui/icons-material";
import SimpleSlider from "../../Common/SimpleSlider";

const MAX_DESCRIPTION_LENGTH = 300;

const SuppliersProducts = ({
    count,
    products,
    status,
    supplierId,
    pagination,
    handlePagination,
    navigate,
}) => {
    const paginationRequest = ({
        pageNo,
        limit,
        sortBy,
        sortField,
        keywords,
    }) => {
        handlePagination({
            pageNo: pageNo !== undefined ? pageNo : pagination.pageNo,
            limit: limit !== undefined ? limit : pagination.limit,
            sortBy: sortBy !== undefined ? sortBy : pagination.sortBy,
            sortField:
                sortField !== undefined ? sortField : pagination.sortField,
            keywords: keywords || pagination.keywords,
        });
    };

    const [expanded, setExpanded] = useState({});

    const toggleExpand = (productId) => {
        setExpanded((prev) => ({
            ...prev,
            [productId]: !prev[productId],
        }));
    };

    return (
        <>
            {products?.length > 0 ? (
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}>
                        <Typography variant="h6">Products ({count})</Typography>
                        {(status === "add-product" || status === "active") && (
                            <Button
                                fullWidth={false}
                                onClick={() =>
                                    navigate(
                                        `/products/active/add/${supplierId}`
                                    )
                                }>
                                <Add />
                                <Typography variant="body2">
                                    Add Products
                                </Typography>
                            </Button>
                        )}
                    </Grid>
                    {products.map((product) => (
                        <Grid item xs={12} key={product?.unique_id}>
                            <Box
                                sx={{
                                    pt: 2,
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    justifyContent: "center",
                                }}>
                                <Stack sx={{ width: "15%", mr: 2 }}>
                                    <SimpleSlider
                                        images={
                                            product?.images &&
                                            product?.images.length > 0
                                                ? product?.images
                                                : [product?.productType?.image]
                                        }
                                    />
                                </Stack>
                                <Stack sx={{ width: "85%" }}>
                                    <Typography variant="h6">
                                        {product?.product_name}
                                    </Typography>
                                    <Box display={"flex"} flexDirection={"row"}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: "inline",
                                                maxWidth: expanded[
                                                    product?.unique_id
                                                ]
                                                    ? "none"
                                                    : "100%",
                                                transition:
                                                    "max-width 0.3s ease",
                                            }}>
                                            {expanded[product?.unique_id]
                                                ? product?.description
                                                : product?.description.length >
                                                  MAX_DESCRIPTION_LENGTH
                                                ? `${product?.description.slice(
                                                      0,
                                                      MAX_DESCRIPTION_LENGTH
                                                  )}... `
                                                : product?.description}
                                            {product?.description.length >
                                                MAX_DESCRIPTION_LENGTH &&
                                                !expanded[
                                                    product?.unique_id
                                                ] && (
                                                    <Typography
                                                        variant="body2"
                                                        component="a"
                                                        sx={{
                                                            color: "primary.main",
                                                            cursor: "pointer",
                                                            textDecoration:
                                                                "underline",
                                                            display: "inline",
                                                        }}
                                                        onClick={() =>
                                                            toggleExpand(
                                                                product?.unique_id
                                                            )
                                                        }>
                                                        Know More
                                                    </Typography>
                                                )}
                                            {expanded[product?.unique_id] && (
                                                <Typography
                                                    variant="body2"
                                                    component="a"
                                                    sx={{
                                                        color: "primary.main",
                                                        cursor: "pointer",
                                                        textDecoration:
                                                            "underline",
                                                        display: "inline",
                                                    }}
                                                    onClick={() =>
                                                        toggleExpand(
                                                            product?.unique_id
                                                        )
                                                    }>
                                                    Know Less
                                                </Typography>
                                            )}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Stack
                    sx={{
                        background: "#F5F5F5",
                        height: "278px",
                        width: "100%",
                        padding: "20px 24px 20px 24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                    }}>
                    <Typography variant="body2">
                        No Products Added Yet
                    </Typography>
                    <Button
                        fullWidth={false}
                        onClick={() =>
                            navigate(`/products/${status}/add/${supplierId}`)
                        }>
                        <Add />
                        <Typography variant="body2">Add Products</Typography>
                    </Button>
                </Stack>
            )}

            {false && pagination && products?.length > 0 ? (
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: count },
                    ]}
                    count={count}
                    rowsPerPage={pagination.limit}
                    page={pagination.pageNo}
                    SelectProps={{
                        inputProps: {
                            "aria-label": "rows per page",
                        },
                        native: true,
                    }}
                    onPageChange={(e, newNo) => {
                        console.log(newNo, "333");
                        paginationRequest({ pageNo: newNo });
                    }}
                    handlePagination={(val) => handlePagination(val)}
                    onRowsPerPageChange={(e) =>
                        paginationRequest({ limit: e.target.value })
                    }
                    ActionsComponent={TablePaginationActions}
                />
            ) : null}
        </>
    );
};

export default SuppliersProducts;
