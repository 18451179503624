import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie, signout } from "../../helpers/cookies";
import { supplierParser, supplierUniqueParser } from './suppliersParser';

const customFetchBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getCookie("paqej_admin_token");
        if (token) {
            headers.set('Authorization', `BEARER ${token}`)
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await customFetchBaseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 403) {
        signout(() => { window.location.href = "/login"; });
    }

    return result;
};

const suppliersApis = createApi({
    reducerPath: 'suppliersApis',
    baseQuery: baseQueryWithReauth,
    keepUnusedDataFor: 0,
    tagTypes: ["Suppliers"],
    endpoints: build => ({
        getSupplierById: build.query({
            query: (params) => ({
                url: "/users/suppliers",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => supplierUniqueParser(response),
            providesTags: ['Suppliers'],
            keepUnusedDataFor: 5,
        }),
        getSuppliers: build.query({
            query: (params) => ({
                url: "/users/suppliers/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => supplierParser(response),
            providesTags: ['Suppliers'],
            keepUnusedDataFor: 5,
        }),
        updateSuppliers: build.mutation({
            query: (payload) => ({
                url: "/users/suppliers/update",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['Suppliers'],
        }),
        updateStatus: build.mutation({
            query: (payload) => ({
                url: "/users/suppliers/update-status",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Suppliers'],
        }),
        updateStatusAll: build.mutation({
            query: (payload) => ({
                url: "/users/suppliers/update-all",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Suppliers'],
        }),
    }),
});


export const {
    useGetSupplierByIdQuery,
    useGetSuppliersQuery, useUpdateSuppliersMutation, useUpdateStatusMutation, useUpdateStatusAllMutation
} = suppliersApis;
export default suppliersApis