import React, { useState } from "react";
import {
    TableCell,
    TableRow,
    Typography,
    Stack,
    Switch,
    Button,
} from "@mui/material";
import TableCommon from "../../Common/Table";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { useUpdateCategoryMutation } from "../../../store/masters/mastersApis";
import VerticalMenu from "../../Common/VerticleAction";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import { PencilIcon } from "../../Common/Icons";
import CustomizedSwitches from "../../Common/CustomSwitch";

const CategoryTableComponent = ({
    loading,
    fetching = false,
    count,
    data,
    edit,
    pagination,
    handlePagination,
}) => {
    const [handleUpdate] = useUpdateCategoryMutation();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);

    const handleDisable = (row) => {
        setCategoryIdToDelete(row);
        setConfirmationOpen(true);
    };

    const handleConfirmDisable = () => {
        handleUpdate({
            id: categoryIdToDelete?.id,
            name: categoryIdToDelete?.name,
            icategoryId: categoryIdToDelete?.icategoryId,
            disabled: !categoryIdToDelete?.disabled,
        });
        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };
    console.log(data);
    let columns = [
        {
            title: "ID No.",
            sort: false,
            // width: "10%",
        },
        {
            title: "Category",
            field: "name",
            sort: true,
            // width: "10%",
        },
        {
            title: "iCategory",
            field: "icategory",
            sort: false,
            // width: "30%",
        },
        /*  {
            title: "Priority",
            field: "priority",
            sort: false,
            width: "8%",
        },
        {
            title: "Homepage",
            field: "homepagedisplay",
            sort: false,
            width: "15%",
        }, */
        {
            title: "Created by & date",
            field: "createdAt",
            sort: true,
            // width: "20%",
        },
        {
            title: "",
            name: "",
            sort: false,
            width: "10%",
        },
    ];

    const renderTableData =
        !loading && data && data.length > 0 ? (
            data.map(function (row, index) {
                console.log(row);
                return (
                    <TableRow
                        key={index}
                        sx={{
                            "td, th": { border: 0, padding: "10px" },
                            ...(row?.disabled && { opacity: 0.4 }),
                        }}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>
                            {row?.name}
                            {row?.disabled ? (
                                <Button
                                    sx={{ ml: 2 }}
                                    disabled
                                    size="small"
                                    variant="contained">
                                    Disabled
                                </Button>
                            ) : null}
                        </TableCell>
                        <TableCell>{row?.icategory?.name}</TableCell>
                        {/*  <TableCell>{row?.priority}</TableCell>
                        <TableCell>
                            {row?.homepagedisplay == 1 ? "Yes" : "No"}
                        </TableCell> */}
                        <TableCell>
                            <Typography variant="body2" fontFamily={500}>
                                {row?.createdBy?.name}
                            </Typography>
                            <Typography variant="caption">
                                {handleDateTimeDefault(row?.createdAt)}
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <VerticalMenu>
                                <Typography
                                    sx={{
                                        gap: 2,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    variant="body2"
                                    onClick={() => edit(row, "Edit")}>
                                    <PencilIcon /> Update
                                </Typography>
                                <Stack
                                    direction="row"
                                    width={"100%"}
                                    justifyContent={"space-between"}>
                                    <Typography
                                        sx={{
                                            gap: 2,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        variant="body2">
                                        {row?.disabled ? "Enable" : "Disable"}
                                    </Typography>
                                    <CustomizedSwitches
                                        checked={confirmationOpen}
                                        onChange={() => handleDisable(row)}
                                    />
                                </Stack>
                            </VerticalMenu>
                        </TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
                <TableCell colSpan={5} align="center">
                    Data not found
                </TableCell>
            </TableRow>
        );

    return (
        <>
            <TableCommon
                columns={columns}
                tableData={renderTableData}
                count={count}
                loading={loading || fetching}
                pagination={pagination}
                handlePagination={handlePagination}
            />

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmDisable}
                title={
                    categoryIdToDelete?.disabled
                        ? "Are you sure you want to enable selected row?"
                        : "Are you sure you want to disable selected row?"
                }
                confirmButtonTitle={
                    categoryIdToDelete?.disabled
                        ? "Yes, Enable"
                        : "Yes, Disable"
                }
            />
        </>
    );
};

export default CategoryTableComponent;
