import React from "react";
import Button from "../../Common/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Formik } from "formik";
import Input from "../../Common/Input";
import * as yup from "yup";
import { Box, InputLabel } from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { sendOtpUrl } from "../../../helpers/constants";
import { axios } from "../../../helpers/axios";

const validateLoginForm = (otpSent) => yup.object().shape({
    email: otpSent ? yup.string().email() : yup.string().email().required("Email is required"),
    otp: otpSent ? yup.string().length(6, 'OTP must be 6 digits').required('OTP is required') : yup.string(),
});

const LoginFormComponent = (props) => {
    const [otpSent, setOtpSent] = React.useState(false);
    const [counter, setCounter] = React.useState(0);
    const { submit, loading } = props;
    const initialValues = {
        email: "vijay@paqej.com",
        otp: "",
    };

    React.useEffect(() => {
        let timer;
        if (otpSent && counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [counter, otpSent]);

    const onFormSubmit = async (values, setFieldValue) => {
        if (!values.otp) {
            const send_otp = await axios.post(sendOtpUrl, { email: values.email });
            if (send_otp.success) {
                setOtpSent(true);
                setCounter(94); // Reset counter to 1:34 (94 seconds)
                // setFieldValue("otp", send_otp?.result); // Pre-fill received OTP for now
                return;
            }
        }
        submit(values);
    };

    const resendOtp = async (email, setFieldValue) => {
        const send_otp = await axios.post(sendOtpUrl, { email });
        if (send_otp.success) {
            setCounter(94); // Reset counter to 1:34 (94 seconds)
            // setFieldValue("otp", send_otp?.result); // Pre-fill received OTP for now
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setFieldValue }) => onFormSubmit(values, setFieldValue)}
                validationSchema={validateLoginForm(otpSent)}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    values,
                    touched,
                    errors,
                }) => (
                    <>
                        <Container maxWidth="sm" sx={{ height: "100vh" }}>
                            <Stack
                                justifyContent="center"
                                sx={{
                                    height: { xs: "100%", sm: "80vh", md: "100vh", lg: "100vh" },
                                    width: "auto",
                                    mx: 4,
                                    gap: "40px",
                                    alignItems: "center",
                                }}
                                spacing={1.5}
                            >
                                <Box sx={{ width: "100%", flexDirection: "column", display: { xs: "flex", md: "none" }, alignItems: "flex-start", justifyContent: "center", gap: "32px" }}>
                                    <img
                                        src="assets/images/logo.png"
                                        alt="paquej-logo"
                                        style={{ width: "211px" }}
                                    />
                                    <Typography variant="h6" style={{ color: "#575757", fontSize: "24px" }}>Sourcing Platform for Packaging Industry</Typography>
                                </Box>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "12px", alignItems: "flex-start" }}>
                                    <Typography
                                        variant="h5"
                                        align="left"
                                        sx={{
                                            fontWeight: "600",
                                            fontSize: "28px",
                                        }}
                                    >
                                        {otpSent ? "Enter OTP" : "Log in to your account"}
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        align="center"
                                        sx={{ fontSize: "15px", color: "#575757" }}
                                    >
                                        {otpSent ? `Enter the OTP sent to ${values?.email}` : "Welcome back! Please enter your details."}
                                    </Typography>
                                </Box>

                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "32px" }}>
                                    <Input
                                        type="text"
                                        fullWidth
                                        label="Email"
                                        placeholder="Enter your email"
                                        id="email"
                                        name="email"
                                        variant="outlined"
                                        onChange={(e) => { setFieldValue("email", e.target.value); setOtpSent(false); }}
                                        onBlur={handleBlur("email")}
                                        value={values.email}
                                        error={errors.email && Boolean(errors.email)}
                                        helperText={errors.email}
                                        size={"large"}
                                    />

                                    {otpSent && (
                                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "6px" }}>
                                            <InputLabel sx={{ fontSize: "14px", color: "#454545", fontWeight: "500" }} shrink={false}>Enter OTP</InputLabel>
                                            <MuiOtpInput length={6} value={values.otp} onChange={(val) => setFieldValue("otp", val)} />
                                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                                                <Typography sx={{ fontSize: "14px", color: "#575757", fontWeight: "400" }}>Didn’t receive the OTP? </Typography>
                                                {counter === 0 ? (
                                                    <Typography sx={{ fontSize: "14px", color: "#ED2024", cursor: "pointer", fontWeight: "600" }} onClick={() => resendOtp(values.email, setFieldValue)}>Resend OTP</Typography>
                                                ) : (
                                                    <Typography sx={{ fontSize: "14px", color: "#575757", fontWeight: "400" }}>
                                                        Resend OTP in {`${Math.floor(counter / 60)}:${String(counter % 60).padStart(2, '0')}`}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    )}

                                    <Button
                                        onClick={handleSubmit}
                                        loading={loading}
                                        fullWidth
                                        size="large"
                                    >
                                        {otpSent ? "Verify OTP" : "Get OTP"}
                                    </Button>
                                </Box>
                            </Stack>
                        </Container>
                    </>
                )}
            </Formik >
        </>
    );
};

export default LoginFormComponent;
