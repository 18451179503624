
import { useNavigate } from "react-router-dom";

// mui component
import { useTheme } from "@emotion/react";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// constants
import { drawerWidth } from "../../helpers/constants";

// third-party
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from 'react-perfect-scrollbar';

// custom component
import LogoSection from "./LogoSection";
import MenuList from "./MenuList";
import { isAuth } from "../../helpers/cookies";

const Sidebar = ({ drawerOpen, drawerToggle, modules }) => {
    const userRoles = isAuth().role;
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const path_url = window.location.pathname;

    const navigate = useNavigate();


    return (
        <Box
            component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders"
        >
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                        borderRight: '1px solid #D6D6D6',
                        transitionDuration: "250ms",
                        transitionProperty: "all",
                        transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)"
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box sx={{ display: { xs: 'block' } }}>
                    <Box sx={{ display: 'flex', p: 2, mx: 'auto', width: "100%" }}>
                        <LogoSection theme={theme} />
                    </Box>
                </Box>
                <BrowserView>
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: 'calc(100vh - 76px)',
                        }}
                    >
                        <MenuList roles={userRoles} modules={modules} navigate={navigate} path_url={path_url} theme={theme} />
                    </PerfectScrollbar>
                </BrowserView>
                <MobileView>
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: 'calc(100vh - 76px)',
                        }}
                    >
                        <MenuList roles={userRoles} modules={modules} navigate={navigate} path_url={path_url} theme={theme} />
                    </PerfectScrollbar>
                </MobileView>
            </Drawer>
        </Box >
    );
}

export default Sidebar;
