import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { handlePaginationState } from "../../../store/products/productsSlice";
import suppliersApis from "../../../store/suppliers/suppliersApis";

const mapStateToProps = (state) => {
    return {
        pagination: state.products.supplierProductsPagination,
    };
};

const mapDispatch = {
    handlePaginationState: handlePaginationState,
    getSuppliers: suppliersApis.endpoints.getSuppliers.initiate,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;