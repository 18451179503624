import React, { Fragment, useState } from 'react';
import {
    IconButton,
    Menu,
    MenuItem,
    Box,
    Divi,
    Dividerder,
    Divider,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const VerticalMenu = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    minWidth: 180,
                    paddingTop: 0,
                    paddingBottom: 0
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    minWidth: 180,
                    paddingTop: 0,
                    paddingBottom: 0
                }}
                PaperProps={{
                    style: {
                        minWidth: 180,
                        paddingTop: 0,
                        paddingBottom: 0
                    },
                }}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                    style: {
                        paddingTop: 0,
                        paddingBottom: 0,
                    }
                }}
                getContentAnchorEl={null}
            >
                {React.Children.map(children, (child, index) => (
                    <Fragment>
                        <MenuItem key={index} onClick={() => { handleClose(); child.props.onClick ? child.props.onClick() : handleClose(); }} style={{ minHeight: 48 }}>
                            {child}
                        </MenuItem>
                        {index !== children.length - 1 && <Divider sx={{ m: "0px !important" }} />}
                    </Fragment>
                ))}
            </Menu>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
            >
                <MoreVertIcon />
            </IconButton>
        </Box>
    );
};

export default VerticalMenu;
