import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const CustomDot = ({ onClick, isActive }) => (
    <div
        onClick={onClick}
        style={{
            width: '5px',
            height: '5px',
            borderRadius: '50%',
            backgroundColor: isActive ? 'red' : 'grey',
            margin: '0 0',
            cursor: 'pointer'
        }}
    />
);

const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '10px',
                zIndex: 1,
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            <ChevronLeft style={{ color: 'grey', fontSize: '30px' }} />
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '10px',
                zIndex: 1,
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            <ChevronRight style={{ color: 'grey', fontSize: '30px' }} />
        </div>
    );
};

const SimpleSlider = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true, // Show navigation dots
        infinite: false, // Disable infinite scrolling
        speed: 500, // Transition speed
        slidesToShow: 1, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        afterChange: index => setCurrentSlide(index),
        customPaging: (i, sd) => {
            return <CustomDot isActive={i === currentSlide} />;
        },
        appendDots: (dots) => (
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                {dots}
            </div>
        ),
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    return (
        <div style={{ position: 'relative', width: '100%', }}>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} style={{ width: "100%", backgroundColor: "#F3F3F3", borderRadius: 5 }}>
                        <img src={image} alt={`Slide ${index + 1}`} style={{ width: "100%", backgroundColor: "#F3F3F3", borderRadius: 5 }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SimpleSlider;
