import React, { useEffect } from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";

import ICategoryTableComponent from "../../../components/admin/icategory-manager/ICategoryTable";
import { ICategoryAddDrawer } from "../../../components/admin/icategory-manager/ICategoryAddDrawer";

import { useGetICategoryListQuery } from "../../../store/masters/mastersApis";
import { defaultPaginate } from "../../../helpers/constants";
import { AddOutlined } from "@mui/icons-material";

const ICategoryManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination } = props;
    const { isLoading, data: icategory, isFetching } = useGetICategoryListQuery(pagination);

    const handleAddICategory = () => {
        props.changeICategoryInitialState({
            showDrawer: true,
            formType: "Add",
            initialValues: null
        })
    }

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [])

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleDrawerClose = () => {
        props.changeICategoryInitialState({ showDrawer: false, formType: "", initialValues: null })
    }

    const handleICategoryEdit = (data, type) => {
        let payload = { id: data.id, name: data.name };
        props.changeICategoryInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    return (
        <Stack spacing={2}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6" >iCategory <span style={{ color: "gray", fontSize: "10px", verticalAlign: "super" }}>({icategory?.total || 0})</span> </Typography>
                </Grid>
                <Grid item xs={6} sx={{ alignSelf: "center" }} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <Button
                        disableElevation
                        variant="contained"
                        sx={{ borderRadius: "8px", marginLeft: 2, display: "flex", alignItems: "center", gap: 1, fontSize: "16px" }}
                        onClick={() => handleAddICategory()}
                    >
                        <AddOutlined />
                        Add new iCategory
                    </Button>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        <ICategoryTableComponent edit={(val, type) => handleICategoryEdit(val, type)} loading={isLoading} fetching={isFetching} count={icategory?.total} data={icategory?.results || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                    </Paper>
                </Grid>
            </Grid>
            <ICategoryAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />
        </Stack >
    );
}

export default ICategoryManagerContainer;