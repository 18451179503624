import { Box, FormControl, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";

const Input = (props) => {
    const {
        name,
        id,
        label,
        value,
        variant = "outlined",
        onChange,
        size = "small",
        ...other
    } = props;
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
            }}>
            <InputLabel
                sx={{ fontSize: "14px", color: "#454545", fontWeight: "500" }}
                shrink={false}>
                {label}
            </InputLabel>
            <TextField
                variant={variant}
                name={name}
                id={id}
                value={value}
                size={size}
                onChange={onChange}
                label=""
                {...other}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
            />
        </Box>
    );
};

export default Input;
