import React, { useEffect } from "react";
import { Box, Button, Card, CardContent, Grid, Paper, Stack, Typography } from "@mui/material";

import ProductImageTableComponent from "../../../components/admin/product-image/ProductImageTable";

import { useGetProductTypesListQuery } from "../../../store/masters/mastersApis";
import { defaultPaginate } from "../../../helpers/constants";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import FilterTabs from "../../../components/Common/FilterTabs";

const ProductImageManagerContainer = (props) => {
    const { pagination } = props;
    const { isLoading, data: category, isFetching } = useGetProductTypesListQuery(pagination);

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [])

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    }

    const handleProductTypesEdit = (data, type) => {
        let payload = {
            id: data.id,
            name: data.name,
            categoryId: data.categoryId,
            image: data.image
        };
        props.changeProductTypesInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    return (
        <Stack spacing={2}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6" >Product Images <span style={{ color: "gray", fontSize: "10px", verticalAlign: "super" }}>({category?.total || 0})</span> </Typography>
                </Grid>
                <Grid item xs={6} sx={{ alignSelf: "center" }} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <SearchRecords handlePagination={handlePagination} pagination={pagination} placeholder={"Search Product Type"} />
                </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
                <Grid item xs={6} sx={{ paddingRight: 2 }}>
                    <Card>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.all || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                All Product Types
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} display={"flex"} flexDirection={"row"} gap={0.2} sx={{ paddingLeft: 1 }}>
                    <Card sx={{ flex: 1 }}>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.with_image || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                With Image
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ flex: 1 }}>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.without_image || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                Without Image
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <FilterTabs handlePagination={handlePagination} pagination={pagination} showImage={true} type={"image"} />

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        {
                            category?.total === 0 ?
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ height: "300px" }}><Typography variant="subtitle" fontWeight={"600"} color="textSecondary">Nothing to display</Typography></Box>
                                :
                                <ProductImageTableComponent edit={(val, type) => handleProductTypesEdit(val, type)} loading={isLoading} fetching={isFetching} count={category?.total} data={category?.results || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Stack >
    );
}

export default ProductImageManagerContainer;