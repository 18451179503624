import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { SupplierAddValidation } from "./SuppliersValidation";
import {
    Drawer,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import IconButtonIcons from "../../Common/IconButtonIcons";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";

import { setSnackBar } from "../../../store/common/commonSlice";
import BasicSelect from "../../Common/Select";
import { SupplierViewComponent } from "./SupplierViewComponent";
import { PencilIcon } from "../../Common/Icons";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import ReasonDialog from "../../Common/ReasonDialog";
import { SupplierAddComponent } from "./SuppliersAddForm";
import {
    useGetSupplierByIdQuery,
    useUpdateStatusMutation,
    useUpdateSuppliersMutation,
} from "../../../store/suppliers/suppliersApis";
//useGetgetCeritQuery
import {
    useGetCeritQuery,
    useGetCategoryQuery,
} from "../../../store/common/commonApis";
export const SuppliersAddDrawer = ({
    supplierId,
    show,
    close,
    formType,
    handleSuppliersEdit,
    params,
}) => {
    const dispatch = useDispatch();
    const [handleUpdate] = useUpdateStatusMutation();
    const [handleSupplierUpdate] = useUpdateSuppliersMutation();
    const [supData, setSupdata] = useState([]);
    const [selectedFilesCert, setSelectedFilesCert] = useState([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [rejectPopupOpen, setRejectPopupOpen] = useState(false);
    const [statusToHandle, setStatusToHandle] = useState(null);
    const [formInitialValues, setFormInitialValues] = useState({});
    const [showProfileUpdate, setShowProfileUpdate] = useState(false);
    const [selectedCert, setSelectedcert] = useState([]);
    const [selectedCatolog, setselectedCatolog] = useState([]);
    const [selectedCategory, setSelectedCaetory] = useState([]);
    const [Deleted_Catalog, setDeletedCatalog] = useState([]);
    const [Deleted_Doc, setDeletedDoc] = useState([]);
    // Only call the query if supplierId is set
    const { data: supplierData } = useGetSupplierByIdQuery(
        { id: supplierId },
        {
            skip: !supplierId, // Skip the query until supplierId is set
        }
    );

    const { data: supplierCertifications } = useGetCeritQuery();
    const { data: supplierCategory } = useGetCategoryQuery();
    console.log(supplierCertifications);
    useEffect(() => {
        if (supplierData) {
            console.log(supplierData, "2222111");
            setSelectedcert(
                supplierData?.entity_business_certifications.map(
                    (item) => item.business_certification_id
                )
            );
            let vatfiles = supplierData?.file_mappings?.filter(
                (item) => item?.file_type == "gst_vat_file"
            );

            let export_license_file = supplierData?.file_mappings?.filter(
                (item) => item?.file_type == "export_license_file"
            );
            if (supplierData && supplierData?.category_mapping) {
                setSelectedCaetory(
                    supplierData?.category_mapping?.map(
                        (item) => item?.category_id
                    )
                );
            }

            setSupdata(supplierData);
            setFormInitialValues({
                id: supplierData?.id,
                name: supplierData?.user?.name,
                gst_vat_number: supplierData?.gst_vat_number,
                gst_vat_file:
                    vatfiles && vatfiles?.length > 0
                        ? vatfiles[0].file_url
                        : "",
                export_license_file:
                    export_license_file && export_license_file?.length > 0
                        ? export_license_file[0].file_url
                        : "",
                business_certified_with: supplierData?.business_certified_with,
                email: supplierData?.user?.email,
                is_business_certified: supplierData?.is_business_certified,
                country_code: supplierData?.country_code,
                mobile_number: supplierData?.mobile_number,
                is_whatsapp: supplierData?.is_whatsapp,
                legal_name: supplierData?.legal_name,
                business_type: supplierData?.business_type,
                suppliers_bio: supplierData?.suppliers_bio,
                export_license_number: supplierData?.export_license_number,
                have_export_license: supplierData?.have_export_license,
                business_customize_requirement:
                    supplierData?.business_customize_requirement,
            });
        }
    }, [supplierData]);

    const handleApprove = (status) => {
        setStatusToHandle(status);
        setConfirmationOpen(true);
    };

    const handleConfirmApprove = async () => {
        let payload = {};

        if (statusToHandle === "reconsider") {
            payload = {
                id: supplierId,
                status: "pending",
            };
        } else if (statusToHandle === "approved") {
            payload = {
                id: supplierId,
                status: "approved",
            };
        }
        let res = await handleUpdate(payload);
        if (res) {
            dispatch(
                setSnackBar({
                    open: true,
                    message: "Supplier approved successfully",
                    severity: "success",
                })
            );
        }

        setConfirmationOpen(false);
        setStatusToHandle(null);
        close();
    };

    const handleCloseApprove = () => {
        setConfirmationOpen(false);
    };

    const handleReject = (status) => {
        setStatusToHandle(status);
        setRejectPopupOpen(true);
    };

    const handleCloseRejectPopup = (reason) => {
        let payload = {
            id: supplierId,
            status: statusToHandle,
            reason: reason,
        };

        handleUpdate(payload);
        setStatusToHandle(null);
        setRejectPopupOpen(false);
        close();
    };

    const onFormSubmit = async (values, resetForm) => {
        let [vatfiles] = supplierData?.file_mappings?.filter(
            (item) => item?.file_type == "gst_vat_file"
        );

        let [export_license_file] = supplierData?.file_mappings?.filter(
            (item) => item?.file_type == "export_license_file"
        );
        let catalog = supplierData?.file_mappings?.filter(
            (item) => item.file_type == "product_catalog"
        );
        let business_valid_certificates = supplierData?.file_mappings?.filter(
            (item) => item.file_type == "business_valid_certificates"
        );

        try {
            let payload = {
                ...values,
                mobile_number: values.mobile_number.toString(),

                business_certified_with: selectedCert,
                category: selectedCategory,
                business_valid_certificates: selectedFilesCert,
                deleted_business_valid_certificates: Deleted_Doc?.filter(
                    (item) => item != 0
                ),
                product_catalog: selectedCatolog,
                deleted_product_catalog: Deleted_Catalog?.filter(
                    (item) => item != 0
                ),
            };

            if (values.export_license_file) {
                payload = {
                    ...payload,
                    export_license_file: [
                        {
                            file_url: values.export_license_file,
                            id: export_license_file?.id
                                ? export_license_file?.id
                                : 0,
                        },
                    ],
                };
            } else {
                delete payload.export_license_file;
            }
            if (values?.gst_vat_file) {
                payload = {
                    ...payload,
                    gst_vat_file: [
                        {
                            id: vatfiles.id ? vatfiles.id : 0,
                            file_url: values?.gst_vat_file,
                        },
                    ],
                };
            } else {
                delete payload.gst_vat_file;
            }

            console.log(payload);
            // return;
            const result = await handleSupplierUpdate(payload);
            console.log(result);
            if (result.data?.success) {
                setDeletedDoc([]);
                setDeletedCatalog([]);
                setSelectedcert([]);
                setselectedCatolog([]);
                setSelectedCaetory([]);

                dispatch(
                    setSnackBar({
                        open: true,
                        message: "Supplier updated successfully",
                        severity: "success",
                    })
                );
                setShowProfileUpdate(false);
                close();
                resetForm();
            } else {
                dispatch(
                    setSnackBar({
                        open: true,
                        message: result?.error?.data?.message,
                        severity: "error",
                    })
                );
            }
        } catch (error) {
            dispatch(
                setSnackBar({
                    open: true,
                    message: error?.data?.message || error.message,
                    severity: "error",
                })
            );
        }
    };

    const handleEdit = async () => {
        handleSuppliersEdit(formInitialValues, "edit");
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{ ...formInitialValues }}
            // onSubmit={(values) => onFormSubmit(values)}
            onSubmit={(values, { resetForm }) => {
                onFormSubmit(values, resetForm);
            }}
            validationSchema={SupplierAddValidation}>
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue,
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: {
                            width: {
                                xs: "100%",
                                md: "55%",
                                sm: "55%",
                                lg: "55%",
                            },
                        },
                    }}
                    onClose={() => {
                        close();
                        setShowProfileUpdate(false);
                    }}>
                    {console.log(values, "values")}
                    <Grid
                        container
                        sx={{ display: "flex" }}
                        direction={"column"}
                        width={"100%"}
                        height={"100%"}>
                        <Grid
                            container
                            flex={0}
                            px={2}
                            py={1}
                            borderBottom={1}
                            borderColor={"rgba(5, 5, 5, 0.06)"}
                            justifyContent={"space-between"}>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">
                                    ID: {supplierData?.unique_id}
                                </Typography>
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons
                                    color="default"
                                    title="Close"
                                    IconComponent={CloseIcon}
                                    onClick={() => close()}
                                />
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={2} overflow={"auto"}>
                            <SupplierViewComponent
                                formType={formType}
                                supplierData={supplierData}
                                showProfileUpdate={showProfileUpdate}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                            />
                            <SupplierAddComponent
                                formType={formType}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                supplierData={supData}
                                setSelectedcert={setSelectedcert}
                                selectedCert={selectedCert}
                                supplierCertifications={supplierCertifications}
                                supplierCategory={supplierCategory}
                                selectedFilesCert={selectedFilesCert}
                                setSelectedFilesCert={setSelectedFilesCert}
                                selectedCategory={selectedCategory}
                                setSelectedCaetory={setSelectedCaetory}
                                selectedCatolog={selectedCatolog}
                                setselectedCatolog={setselectedCatolog}
                                setDeletedDocs={setDeletedDoc}
                                setDeletedCatalog={setDeletedCatalog}
                            />
                        </Grid>
                        {showProfileUpdate ? (
                            <Grid
                                flexShrink={0}
                                borderTop={1}
                                borderColor={"rgba(152, 188, 252, 0.16)"}
                                sx={{ padding: "8px 16px" }}>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            width: "auto",
                                        }}>
                                        <Button
                                            size="medium"
                                            type="submit"
                                            variant="outlined"
                                            loading={false}
                                            onClick={() => handleSubmit()}>
                                            Save Data
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : formType === "view" && params === "new" ? (
                            <Grid
                                flexShrink={0}
                                borderTop={1}
                                borderColor={"rgba(152, 188, 252, 0.16)"}
                                sx={{ padding: "8px 16px" }}>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}>
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            color="default"
                                            loading={false}
                                            onClick={() => handleEdit()}>
                                            <PencilIcon />
                                            <Typography color={"#2B2B2B"}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            width: "auto",
                                        }}>
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            sx={{ width: "166px" }}
                                            type="button"
                                            loading={false}
                                            onClick={() =>
                                                handleReject("rejected")
                                            }>
                                            Reject Supplier
                                        </Button>
                                        <Button
                                            size="medium"
                                            type="submit"
                                            sx={{ width: "166px" }}
                                            loading={false}
                                            onClick={() =>
                                                handleApprove("approved")
                                            }>
                                            Approve Supplier
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (formType === "edit" && params === "new") ||
                          (formType === "edit" && params === "active") ? (
                            <Grid
                                flexShrink={0}
                                borderTop={1}
                                borderColor={"rgba(152, 188, 252, 0.16)"}
                                sx={{ padding: "8px 16px" }}>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            width: "auto",
                                        }}>
                                        <Button
                                            size="medium"
                                            type="submit"
                                            variant="outlined"
                                            loading={false}
                                            onClick={() => handleSubmit()}>
                                            Save Data
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : formType === "view" && params === "active" ? (
                            <Grid
                                flexShrink={0}
                                borderTop={1}
                                borderColor={"rgba(152, 188, 252, 0.16)"}
                                sx={{ padding: "8px 16px" }}>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}>
                                        <Button
                                            size="medium"
                                            variant="outlined"
                                            color="default"
                                            loading={false}
                                            onClick={() => handleEdit()}>
                                            <PencilIcon />
                                            <Typography color={"#2B2B2B"}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            width: "auto",
                                        }}>
                                        <Button
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            loading={false}
                                            onClick={() =>
                                                handleReject("suspended")
                                            }>
                                            Suspend
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : formType === "view" && params === "profile" ? (
                            <Grid
                                flexShrink={0}
                                borderTop={1}
                                borderColor={"rgba(152, 188, 252, 0.16)"}
                                sx={{ padding: "8px 16px" }}>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}>
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            width: "auto",
                                        }}>
                                        <Button
                                            size="medium"
                                            type="submit"
                                            variant="outlined"
                                            color="default"
                                            loading={false}
                                            onClick={() =>
                                                setShowProfileUpdate(true)
                                            }>
                                            <PencilIcon />
                                            <Typography color={"#2B2B2B"}>
                                                Add Profile
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>

                    {/* Confirmation Dialog */}
                    <ConfirmationDialog
                        open={confirmationOpen}
                        onClose={handleCloseApprove}
                        onConfirm={handleConfirmApprove}
                        title={
                            statusToHandle === "reconsider"
                                ? "Are you sure you want to reconsider?"
                                : "All the details are correct"
                        }
                        subtitle={
                            statusToHandle === "reconsider"
                                ? null
                                : "Make sure all the details are correct."
                        }
                        confirmButtonTitle={"Yes, Sure"}
                    />
                    {/* Reject Dialog */}
                    <ReasonDialog
                        open={rejectPopupOpen}
                        onClose={handleCloseRejectPopup}
                        onConfirm={handleCloseRejectPopup}
                        title={
                            statusToHandle === "rejected"
                                ? "Reason to reject"
                                : statusToHandle === "suspended"
                                ? "Reason to suspend"
                                : null
                        }
                        buttonTitle={
                            statusToHandle === "rejected"
                                ? "Reject"
                                : statusToHandle === "suspended"
                                ? "Suspend"
                                : null
                        }
                    />
                </Drawer>
            )}
        </Formik>
    );
};
