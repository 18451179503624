import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie } from "../../helpers/cookies";

const commonApis = createApi({
    reducerPath: 'commonApis',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getCookie("paqej_admin_token");
            if (token) {
                headers.set('Authorization', `BEARER ${token}`)
            }
            return headers;
        }
    }),
    keepUnusedDataFor: 0,
    tagTypes: ["PaymentHistory"],
    endpoints: build => ({
        getDashboardCount: build.query({
            query: (params) => ({
                url: "/common/dashboard-data",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
        }),
        getCerit: build.query({
            query: () => ({
                url: "/masters/certified/dropdown",
                method: "GET",
            }),
            transformResponse: (response) => response.result,
        }),
        getCategory: build.query({
            query: () => ({
                url: "/masters/category/dropdown",
                method: "GET",
            }),
            transformResponse: (response) => response.result,
        }),
        handleImageUpload: build.mutation({
            query: (payload) => ({
                url: "/common/upload-single-image",
                method: "POST",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => response.result,
        }),
        handleMultipleImageUpload: build.mutation({
            query: (payload) => ({
                url: "/common/upload-multiple-image",
                method: "POST",
                body: payload,
                formData: true
            }),
            transformResponse: (response) => response.result,
        }),
    }),
});

export const { useGetDashboardCountQuery, useGetCeritQuery, useGetCategoryQuery, useHandleImageUploadMutation, useHandleMultipleImageUploadMutation } = commonApis;
export default commonApis;