import React from "react";
import { Formik } from "formik";
import { SupplierTypesValidation } from "./SupplierTypesValidation";
import { Drawer, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined"
import IconButtonIcons from "../../Common/IconButtonIcons";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { useDispatch } from "react-redux";

import { setSnackBar } from "../../../store/common/commonSlice";
import { useAddNewSupplierTypesMutation, useUpdateSupplierTypesMutation } from "../../../store/masters/mastersApis";
import BasicSelect from "../../Common/Select";

export const SupplierTypesAddDrawer = ({ initialValues, show, close, formType }) => {
    const dispatch = useDispatch();
    const [addNewSupplierTypes, { isLoading: addSupplierTypesLoading }] = useAddNewSupplierTypesMutation();
    const [updateSupplierTypes, { isLoading: updateSupplierTypesLoading }] = useUpdateSupplierTypesMutation();

    const disabled = formType === 'View' ? true : false;

    const onFormSubmit = async (values, resetForm) => {
        try {
            let payload = { ...values };

            if (formType === "Edit") {
                await updateSupplierTypes(payload).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Supplier Type updated successfully",
                    severity: "success",
                }))
            } else {
                await addNewSupplierTypes(payload).unwrap();
                dispatch(setSnackBar({
                    open: true,
                    message: "Supplier Type created successfully",
                    severity: "success",
                }))
            }
            close();
            resetForm();
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };


    return (
        <Formik
            enableReinitialize
            initialValues={{ ...initialValues }}
            // onSubmit={(values) => onFormSubmit(values)}
            onSubmit={(values, { resetForm }) => {
                onFormSubmit(values, resetForm);
            }}
            validationSchema={SupplierTypesValidation}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                setFieldValue
            }) => (
                <Drawer
                    anchor={"right"}
                    open={show}
                    PaperProps={{
                        sx: { width: { xs: '100%', md: '25%', sm: "25%", lg: "25%" } },
                    }}
                    onClose={() => close()}
                >
                    <Grid container sx={{ display: "flex" }} direction={"column"} width={"100%"} height={"100%"} >
                        <Grid container flex={0} px={2} py={1} borderBottom={1} borderColor={"rgba(5, 5, 5, 0.06)"} justifyContent={"space-between"}>
                            <Grid item alignSelf={"center"}>
                                <Typography variant="h6">{formType === "Add" ? "Create new SupplierTypes" : "Update SupplierTypes"}</Typography>
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <IconButtonIcons color="default" title="Close" IconComponent={CloseIcon} onClick={() => close()} />
                            </Grid>
                        </Grid>
                        <Grid flex={1} px={2} py={2} overflow={"auto"}>
                            <Grid container spacing={2} >
                                {
                                    formType !== 'Add' && (
                                        <Grid item xs={12} md={12}>
                                            <Input
                                                label="ID number"
                                                value={values?.id || ""}
                                                fullWidth
                                                disabled={true}
                                                sx={{ backgroundColor: "#FAFAFA" }}
                                            />
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} md={12}>
                                    <Input
                                        id='name'
                                        name="name"
                                        label="Supplier"
                                        placeholder="Enter Supplier type"
                                        onChange={handleChange("name")}
                                        value={values?.name || ""}
                                        error={Boolean(errors.name)}
                                        helperText={errors.name}
                                        fullWidth
                                        disabled={disabled}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            formType !== 'View'
                                ?
                                <Grid flexShrink={0} borderTop={1} borderColor={"rgba(152, 188, 252, 0.16)"} sx={{ padding: "8px 16px" }}>
                                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Grid sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                            <Button size="medium" type="submit" loading={addSupplierTypesLoading || updateSupplierTypesLoading} onClick={() => handleSubmit()}>Save Supplier Type</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </Drawer>
            )
            }
        </Formik >
    )
}