import { get } from "lodash-es";

export const supplierParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.results)
            console.log(response)
        response.results = response.results?.map((row, key) => {
            return {
                id: get(row, "id", ""),
                unique_id: get(row, "unique_id", ""),
                user: get(row, "user", null),
                gst_vat_number: get(row, "gst_vat_number", null),
                createdAt: get(row, "createdAt", new Date()),

                legal_name: get(row, "legal_name", "Devarakonda Technologies Pvt. Ltd"),
                business_location: get(row, "business_location", "India"),
                gst_vat_number: get(row, "gst_vat_number", "123456789"),
                productCount: get(row, "productCount", 0),
            }
        })

        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const supplierUniqueParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        if (response) {
            // Transform file_mappings into an object where each file_type is a key
            const fileMappingsTransformed = response.file_mappings.reduce((acc, file) => {
                if (!acc[file.file_type]) {
                    acc[file.file_type] = [];
                }
                acc[file.file_type].push(file.file_url);
                return acc;
            }, {});

            return {
                ...response,
                ...fileMappingsTransformed
            };
        }

        return response;
    } catch (error) {
        throw new Error(error);
    }
};