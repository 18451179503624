import React, { useEffect, useState } from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel,
    Link,
    Paper,
    Stack,
    Typography,
} from "@mui/material";

import { useGetSupplierByIdQuery } from "../../../store/suppliers/suppliersApis";
import DashboardSkeleton from "../../../components/Common/DashboardSkeleton";
import { NavigateNext, Save } from "@mui/icons-material";
import { getStatusCamelName } from "../../../helpers/constants";
import {
    useCreateUpdateProductsMutation,
    useGetProductsBySuppliersQuery,
} from "../../../store/products/productsApis";
import AutoCompleteServerSide from "../../../components/Common/AutoCompleteServerSide";
import * as yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import StyledTextarea from "../../../components/Common/StyledTextarea";
import { MultipleFileUploadToServer } from "../../../components/Common/MultipleFileUploadToServer";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";
import productTypes from "../../admin/product-types";

const SupplierProductsAddContainer = (props) => {
    const dispatch = useDispatch();
    const [productLoading, setProductLoading] = useState(true);
    const { params, pagination, getProductTypesList } = props;
    const status = params?.status;
    const supplierId = params?.supplier_id;

    const [selectedProductTypes, setSelectedProductTypes] = useState([]);
    const [initialValues, setInitialValues] = useState({ products: [] });

    const [createUpdateProduct] = useCreateUpdateProductsMutation();

    const {
        data: supplier,
        isFetching,
        isLoading,
        refetch: refetchSupplier,
    } = useGetSupplierByIdQuery({ unique_id: supplierId });
    const {
        data: products,
        isFetching: fetchingProduct,
        isLoading: loadingProduct,
        refetch: refetchProducts,
    } = useGetProductsBySuppliersQuery({ supplier_id: supplierId });
    console.log(selectedProductTypes);
    useEffect(() => {
        if (products && products.results) {
            const filteringData = products.results.map((item) => ({
                id: item.productTypeId,
                name: item.product_name,
            }));

            setSelectedProductTypes(filteringData);

            const filterInitialValues = products.results.map((item) => ({
                id: item.id,
                product_name: item.product_name,
                productTypeId: item.productTypeId,
                supplierId: item.supplierId,
                description: item.description,
                hasImages: item.hasImages,
                images: item.images ? item.images : [],
            }));

            setInitialValues({ products: filterInitialValues });
            setProductLoading(false);
        }
    }, [products]);

    const handleProductSelect = (val) => {
        if (val && val.length > 0) {
            setSelectedProductTypes(val);

            const filterInitialValues = val.map((item) => {
                // Check if the product is already added in initialValues
                const existingProduct = initialValues.products?.find(
                    (product) => product.productTypeId === item.id
                );

                if (!existingProduct) {
                    return {
                        product_name: item.name,
                        productTypeId: item.id,
                        supplierId: supplier.id,
                        description: "",
                        hasImages: false,
                        images: [],
                    };
                } else {
                    return existingProduct;
                }
            });

            setInitialValues({ products: filterInitialValues });
        } else {
            setSelectedProductTypes([]);
            setInitialValues({ products: [] });
        }
    };

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="inherit"
            href="/"
            onClick={(e) => {
                e.preventDefault();
                props.navigate(`/products/${status}`);
            }}>
            {getStatusCamelName[status]} Products
        </Link>,
        <Link
            underline="hover"
            key="1"
            color="inherit"
            href="/"
            onClick={(e) => {
                e.preventDefault();
                props.navigate(`/products/${status}/${supplierId}`);
            }}>
            {supplier?.user?.name}
        </Link>,
        <Typography key="3" color="primary">
            Add Products
        </Typography>,
    ];

    const handleFetchProductTypes = async (val) => {
        const res = await getProductTypesList(val);

        const filteringData = res?.data?.results.map((item) => ({
            id: item.id,
            name: item.name,
        }));
        return { data: { result: filteringData } };
    };

    const validationSchema = yup.object({
        products: yup.array().of(
            yup.object({
                description: yup.string().required("Description is required"),
                hasImages: yup.boolean(),
                images: yup
                    .array()
                    .of(yup.string().url().nullable())
                    .max(5, "You can upload up to 5 images"),
            })
        ),
    });
    console.log(selectedProductTypes);
    const handleSubmit = async (values) => {
        console.log(values);
        try {
            const result = await createUpdateProduct({
                products: values,
            }).unwrap();

            console.log(result, "result");
            if (result) {
                dispatch(
                    setSnackBar({
                        open: true,
                        message: "Products updated successfully",
                        severity: "success",
                    })
                );

                props.navigate(`/products/active/${supplierId}`);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {isLoading || loadingProduct || productLoading ? (
                <DashboardSkeleton />
            ) : (
                <Stack spacing={2}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12}>
                            <Breadcrumbs
                                separator={<NavigateNext fontSize="small" />}
                                aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>

                    <Grid container gap={2}>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    p: 2,
                                    color: "#071B2A",
                                    fontWeight: "400",
                                }}
                                elevation={0}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        alignSelf: "center",
                                        paddingBottom: 2,
                                    }}>
                                    <Typography variant="h6">
                                        Add Products
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "6px",
                                        }}>
                                        <InputLabel
                                            sx={{
                                                fontSize: "14px",
                                                color: "#454545",
                                                fontWeight: "500",
                                            }}
                                            shrink={false}>
                                            Choose to add Product Types
                                        </InputLabel>
                                        <AutoCompleteServerSide
                                            name="product_types"
                                            id="product_types"
                                            fullWidth
                                            fetchDataFunction={(d) =>
                                                handleFetchProductTypes(d)
                                            }
                                            onChange={(val, current) =>
                                                handleProductSelect(
                                                    val,
                                                    current
                                                )
                                            }
                                            defaultValue={selectedProductTypes}
                                            isMultiple={true}
                                            apiParams={{ state: true }}
                                        />
                                    </Box>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize
                            onSubmit={(values) => {
                                console.log(values);
                                handleSubmit(values?.products);
                            }}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                            }) => (
                                <Form
                                    style={{
                                        width: "100%",
                                        gap: "16px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}>
                                    <FieldArray name="products">
                                        {({ push, remove }) => (
                                            <>
                                                {selectedProductTypes &&
                                                    selectedProductTypes.length >
                                                        0 &&
                                                    selectedProductTypes.map(
                                                        (type, index) => (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                key={type.id}>
                                                                <Paper
                                                                    sx={{
                                                                        p: 2,
                                                                        color: "#071B2A",
                                                                        fontWeight:
                                                                            "400",
                                                                    }}
                                                                    elevation={
                                                                        0
                                                                    }>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sx={{
                                                                            alignSelf:
                                                                                "center",
                                                                            paddingBottom: 2,
                                                                        }}>
                                                                        <Typography
                                                                            variant="h6"
                                                                            color={
                                                                                "primary"
                                                                            }>
                                                                            {
                                                                                type.name
                                                                            }
                                                                        </Typography>
                                                                    </Grid>

                                                                    {/* Dynamic Form Fields */}
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            2
                                                                        }>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }>
                                                                            <InputLabel>
                                                                                Product
                                                                                Description{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color: "red",
                                                                                    }}>
                                                                                    *
                                                                                </span>{" "}
                                                                            </InputLabel>
                                                                            <StyledTextarea
                                                                                name={`products.${index}.description`}
                                                                                value={
                                                                                    values
                                                                                        ?.products[
                                                                                        index
                                                                                    ]
                                                                                        ?.description
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setFieldValue(
                                                                                        `products.${index}.description`,
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                minRows={
                                                                                    4
                                                                                }
                                                                                fullWidth
                                                                                onBlur={
                                                                                    handleBlur
                                                                                }
                                                                                error={
                                                                                    touched
                                                                                        ?.products?.[
                                                                                        index
                                                                                    ]
                                                                                        ?.description &&
                                                                                    Boolean(
                                                                                        errors
                                                                                            .products?.[
                                                                                            index
                                                                                        ]
                                                                                            ?.description
                                                                                    )
                                                                                }
                                                                                helperText={
                                                                                    touched
                                                                                        ?.products?.[
                                                                                        index
                                                                                    ]
                                                                                        ?.description &&
                                                                                    errors
                                                                                        .products?.[
                                                                                        index
                                                                                    ]
                                                                                        ?.description
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        name={`products.${index}.hasImages`}
                                                                                        checked={
                                                                                            values
                                                                                                ?.products[
                                                                                                index
                                                                                            ]
                                                                                                ?.hasImages
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            setFieldValue(
                                                                                                `products.${index}.hasImages`,
                                                                                                e
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label="Do you have product Images?"
                                                                            />
                                                                        </Grid>

                                                                        {values
                                                                            ?.products[
                                                                            index
                                                                        ]
                                                                            ?.hasImages && (
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <MultipleFileUploadToServer
                                                                                    name="images"
                                                                                    onChange={(
                                                                                        files
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            `products.${index}.images`,
                                                                                            files
                                                                                        );
                                                                                    }}
                                                                                    value={
                                                                                        values
                                                                                            .products[
                                                                                            index
                                                                                        ]
                                                                                            .images ||
                                                                                        ""
                                                                                    }
                                                                                    label="Upload Images (up to 5)"
                                                                                    error={Boolean(
                                                                                        errors.images
                                                                                    )}
                                                                                    helperText={
                                                                                        errors.images
                                                                                    }
                                                                                    multiple={
                                                                                        true
                                                                                    }
                                                                                    maxLength={
                                                                                        5
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                        )
                                                    )}
                                            </>
                                        )}
                                    </FieldArray>

                                    <Grid
                                        flexShrink={0}
                                        sx={{
                                            padding: "8px 16px",
                                            position: "sticky",
                                            bottom: 10,
                                        }}>
                                        <Grid
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}>
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 1,
                                                }}>
                                                <Button
                                                    size="medium"
                                                    variant="contained"
                                                    type="submit"
                                                    loading={false}>
                                                    <Save fontSize="small" />
                                                    <Typography sx={{ ml: 1 }}>
                                                        Save
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Stack>
            )}
        </>
    );
};

export default SupplierProductsAddContainer;
