import React, { Component } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

class RichTextEditor extends Component {

    render() {
        return (
            <div style={{ minHeight: '500px' }}>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.props.defaultValue}
                    config={{
                        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo'],
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.props.setValue(data);
                    }}
                />
            </div>
        );
    }
}

export default RichTextEditor;