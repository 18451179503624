import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie, signout } from "../../helpers/cookies";
import { icategoryParser, categoryParser, productTypesParser, supplierTypesParser } from './mastersParser';

const customFetchBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getCookie("paqej_admin_token");
        if (token) {
            headers.set('Authorization', `BEARER ${token}`)
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await customFetchBaseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 403) {
        signout(() => { window.location.href = "/login"; });
    }

    return result;
};

const mastersApis = createApi({
    reducerPath: 'mastersApis',
    baseQuery: baseQueryWithReauth,
    keepUnusedDataFor: 0,
    tagTypes: ["ICategory", "ICategoryDropdown", "Category", "CategoryDropdown", "ProductTypes", "ProductTypesDropdown", "SupplierTypes", "Certified"],
    endpoints: build => ({
        // iCategory
        getICategoryList: build.query({
            query: (params) => ({
                url: "/masters/icategory/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => icategoryParser(response),
            providesTags: ['ICategory'],
            keepUnusedDataFor: 5,
        }),
        getICategoryDropdown: build.query({
            query: (params) => ({
                url: "/masters/icategory/dropdown",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
            providesTags: ['ICategoryDropdown'],
            keepUnusedDataFor: 5,
        }),
        addNewICategory: build.mutation({
            query: (payload) => ({
                url: "/masters/icategory",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['ICategory'],
        }),
        updateICategory: build.mutation({
            query: (payload) => ({
                url: "/masters/icategory",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['ICategory'],
        }),
        deleteICategory: build.mutation({
            query: (params) => ({
                url: "/masters/icategory",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['ICategory'],
        }),
        // Category
        getCategoryList: build.query({
            query: (params) => ({
                url: "/masters/category/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => categoryParser(response),
            providesTags: ['Category'],
            keepUnusedDataFor: 5,
        }),
        getCategoryHomePageList: build.query({
            query: (params) => ({
                url: "/masters/admin_displaycategory/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => categoryParser(response),
            providesTags: ['Category'],
            keepUnusedDataFor: 5,
        }),
        getCategoryDropdown: build.query({
            query: (params) => ({
                url: "/masters/category/dropdown",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => response.result,
            providesTags: ['CategoryDropdown'],
            keepUnusedDataFor: 5,
        }),
        addNewCategory: build.mutation({
            query: (payload) => ({
                url: "/masters/category",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Category'],
        }),
        editpriorityCategory: build.mutation({
            query: (payload) => ({
                url: "/masters/category/editpriority",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Category'],
        }),
        updateCategory: build.mutation({
            query: (payload) => ({
                url: "/masters/category",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Category'],
        }),
        deleteCategory: build.mutation({
            query: (params) => ({
                url: "/masters/category",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Category'],
        }),
        // ProductTypes
        getProductTypesList: build.query({
            query: (params) => ({
                url: "/masters/product-types/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => productTypesParser(response),
            providesTags: ['ProductTypes'],
            keepUnusedDataFor: 5,
        }),
        addNewProductTypes: build.mutation({
            query: (payload) => ({
                url: "/masters/product-types",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['ProductTypes'],
        }),
        updateProductTypes: build.mutation({
            query: (payload) => ({
                url: "/masters/product-types",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['ProductTypes'],
        }),
        disableProductTypes: build.mutation({
            query: (payload) => ({
                url: "/masters/product-types-disable",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['ProductTypes'],
        }),
        deleteProductTypes: build.mutation({
            query: (params) => ({
                url: "/masters/product-types",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['ProductTypes'],
        }),

        // Supplier Type
        getSupplierTypesList: build.query({
            query: (params) => ({
                url: "/masters/supplier-types/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => supplierTypesParser(response),
            providesTags: ['SupplierTypes'],
            keepUnusedDataFor: 5,
        }),
        addNewSupplierTypes: build.mutation({
            query: (payload) => ({
                url: "/masters/supplier-types",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['SupplierTypes'],
        }),
        updateSupplierTypes: build.mutation({
            query: (payload) => ({
                url: "/masters/supplier-types",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['SupplierTypes'],
        }),
        deleteSupplierTypes: build.mutation({
            query: (params) => ({
                url: "/masters/supplier-types",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['SupplierTypes'],
        }),

        // Certified
        getCertifiedList: build.query({
            query: (params) => ({
                url: "/masters/certified/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => supplierTypesParser(response),
            providesTags: ['Certified'],
            keepUnusedDataFor: 5,
        }),
        addNewCertified: build.mutation({
            query: (payload) => ({
                url: "/masters/certified",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Certified'],
        }),
        updateCertified: build.mutation({
            query: (payload) => ({
                url: "/masters/certified",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Certified'],
        }),
        deleteCertified: build.mutation({
            query: (params) => ({
                url: "/masters/certified",
                method: "DELETE",
                params: params
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: ['Certified'],
        }),
    }),
});

export const {
    useGetICategoryListQuery, useAddNewICategoryMutation, useUpdateICategoryMutation, useDeleteICategoryMutation, useGetICategoryDropdownQuery,
    useGetCategoryListQuery,
    useGetCategoryHomePageListQuery,
    useAddNewCategoryMutation,
    useEditpriorityCategoryMutation,
    useUpdateCategoryMutation, useDeleteCategoryMutation, useGetCategoryDropdownQuery,
    useGetProductTypesListQuery, useAddNewProductTypesMutation, useUpdateProductTypesMutation, useDeleteProductTypesMutation, useDisableProductTypesMutation,
    useGetSupplierTypesListQuery, useAddNewSupplierTypesMutation, useUpdateSupplierTypesMutation, useDeleteSupplierTypesMutation,
    useGetCertifiedListQuery, useAddNewCertifiedMutation, useUpdateCertifiedMutation, useDeleteCertifiedMutation
} = mastersApis;
export default mastersApis;