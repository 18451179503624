import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import ReactFlagsSelect from "react-flags-select";
import Input from "../../Common/Input";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import { IoMdClose } from "react-icons/io";

import React, { useEffect, useState } from "react";
import CountryCodeIcon from "../../Common/CountryCodeIcon";
import { SearchOutlined } from "@mui/icons-material";
import { uploadSingleFile } from "../../../helpers/constants";
import { Select, MenuItem, OutlinedInput, ListItemText } from "@mui/material";

export const SupplierAddComponent = ({
    formType,
    handleChange,
    setFieldValue,
    errors,
    values,
    supplierCertifications,
    selectedCert,
    setSelectedcert,
    supplierData,
    selectedFilesCert,
    setSelectedFilesCert,
    supplierCategory,
    selectedCategory,
    setselectedCatolog,
    selectedCatolog,
    setSelectedCaetory,
    setDeletedCatalog,
    setDeletedDocs,
}) => {
    const [uploadProgress, setUploadProgress] = useState({});
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [uploadProgressexportLicense, setUploadProgressexportLicense] =
        useState({});
    const [selectedFilesexportLicense, setSelectedFilesexportLicense] =
        useState([]);

    const [uploadProgressCert, setUploadProgressCert] = useState({});

    useEffect(() => {
        if (supplierData) {
            console.log(supplierData);
            let business_valid_certificates =
                supplierData?.file_mappings?.filter(
                    (item) => item.file_type == "business_valid_certificates"
                );
            let gst_vat_file_given = supplierData?.file_mappings?.filter(
                (item) => item.file_type == "gst_vat_file"
            );
            let export_license_file_given = supplierData?.file_mappings?.filter(
                (item) => item?.file_type == "export_license_file"
            );

            if (gst_vat_file_given && gst_vat_file_given.length > 0) {
                setSelectedFiles([
                    {
                        name: String(gst_vat_file_given[0].file_url).substring(
                            gst_vat_file_given[0].file_url.lastIndexOf("/") + 1
                        ),
                    },
                ]);
                setUploadProgress({
                    [String(gst_vat_file_given[0].file_url).substring(
                        gst_vat_file_given[0].file_url.lastIndexOf("/") + 1
                    )]: 100,
                });
            }
            if (
                export_license_file_given &&
                export_license_file_given.length > 0
            ) {
                setSelectedFilesexportLicense([
                    {
                        name: String(
                            export_license_file_given[0].file_url
                        ).substring(
                            export_license_file_given[0].file_url.lastIndexOf(
                                "/"
                            ) + 1
                        ),
                    },
                ]);
                setUploadProgressexportLicense({
                    [String(export_license_file_given[0].file_url).substring(
                        export_license_file_given[0].file_url.lastIndexOf("/") +
                            1
                    )]: 100,
                });
            }

            if (
                business_valid_certificates &&
                business_valid_certificates?.length > 0
            ) {
                setSelectedFilesCert(
                    business_valid_certificates?.map((item) => {
                        let a = String(item?.file_url).split("/");

                        return {
                            id: item.id,
                            d_id: item.id,

                            file_url: item?.file_url,
                            name: a[a.length - 1],
                        };
                    })
                );
            }
            let catalog = supplierData?.file_mappings?.filter(
                (item) => item.file_type == "product_catalog"
            );

            if (catalog && catalog?.length > 0) {
                setselectedCatolog(
                    catalog?.map((item) => {
                        let a = String(item?.file_url).split("/");

                        return {
                            id: item.id,
                            d_id: item.id,

                            file_url: item?.file_url,
                            name: a[a.length - 1],
                        };
                    })
                );
            }
        }
    }, [supplierData]);
    console.log(selectedFilesCert);
    const handleChangeSelected = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedcert(typeof value === "string" ? value.split(",") : value);
    };
    const handleChangeSelectedcategory = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCaetory(
            typeof value === "string" ? value.split(",") : value
        );
    };

    const handleFileChange = (
        field,
        e,
        setSelected,
        setUpload,
        handleFileUpload
    ) => {
        const files = Array.from(e.target.files);
        // console.log(e.target.files);
        // return;
        files.forEach((file) =>
            handleFileUpload(file, setSelected, setUpload, field)
        );
    };
    const handleFileChangeMulti = (
        field,
        e,
        setSelected,
        setUpload,
        handleFileUpload
    ) => {
        const files = Array.from(e.target.files);

        files.forEach((file) =>
            handleFileUpload(file, setSelected, setUpload, field)
        );
    };
    const handleFileUpload = async (file, setSelected, setUpload, field) => {
        console.log(field);
        setSelected((prevFiles) => [file]);
        setUpload((prevProgress) => ({
            [file.name]: 0,
        }));
        let result = await uploadSingleFile(file, setUpload);

        if (result) {
            if (field == "gst_vat_file") {
                setFieldValue("gst_vat_file", result?.location);
            } else if (field == "export_license_file") {
                setFieldValue("export_license_file", result?.location);
            }
        }
    };
    const handleFileUploadMulti = async (
        file,
        setSelected,
        setUpload,
        field
    ) => {
        setUpload((prevProgress) => ({
            ...prevProgress,
            [file.name]: 0,
        }));

        let result = await uploadSingleFile(file, setUpload);

        if (result) {
            setSelected((prev) => [
                ...prev,
                {
                    id: 0,
                    d_id: uuidv4(),
                    file_url: result?.location,
                    name: file.name,
                },
            ]);
        }
    };
    if (formType !== "edit") return null;

    const isSelected = (value) => selectedCert.indexOf(value) !== -1;
    const isSelectedCategory = (value) =>
        selectedCategory.indexOf(value) !== -1;
    const removecert = (file) => {
        setSelectedFilesCert((prevfiles) =>
            prevfiles.filter((item) => item.d_id != file.d_id)
        );
        setDeletedDocs((prev) => [...prev, file?.id]);
    };
    const removecatalog = (file) => {
        setselectedCatolog((prevfiles) =>
            prevfiles.filter((item) => item.d_id != file.d_id)
        );
        setDeletedCatalog((prev) => [...prev, file?.id]);
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Input
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Enter Name Name"
                    onChange={handleChange("name")}
                    value={values?.name || ""}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                    }}>
                    <InputLabel
                        sx={{
                            fontSize: "14px",
                            color: "#454545",
                            fontWeight: "500",
                        }}
                        shrink={false}>
                        Country
                    </InputLabel>
                    <ReactFlagsSelect
                        className="menu-flags custom-scroll-style"
                        selected={values?.country_code || ""}
                        searchPlaceholder="Search "
                        placeholder="Select the Country"
                        searchable
                        onSelect={(countryCode) =>
                            setFieldValue("country_code", countryCode)
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                    }}>
                    <InputLabel
                        sx={{
                            fontSize: "14px",
                            color: "#454545",
                            fontWeight: "500",
                        }}
                        shrink={false}>
                        Mobile Number
                    </InputLabel>
                    <TextField
                        type="number"
                        name="mobile_number"
                        size="small"
                        id="outlined-search"
                        value={values.mobile_number}
                        style={{ minWidth: 300 }}
                        onChange={handleChange("mobile_number")}
                        error={Boolean(errors.mobile_number)}
                        helperText={errors.mobile_number}
                        fullWidth
                        InputProps={{
                            startAdornment: values?.country_code ? (
                                <CountryCodeIcon
                                    countryCode={values?.country_code}
                                    showNumber={true}
                                />
                            ) : null,
                        }}
                    />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                            checked={values?.is_whatsapp}
                            onChange={handleChange("is_whatsapp")}
                            name="is_whatsapp"
                            color="primary"
                        />
                        <Typography variant="body2">
                            This is my whatsapp number
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Input
                    id="email"
                    name="email"
                    label="Email Address"
                    placeholder="Enter Email address"
                    onChange={handleChange("email")}
                    value={values?.email || ""}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Input
                    id="legal_name"
                    name="legal_name"
                    label="Legal Name of Business as per Govt. Records"
                    placeholder="Enter Legal Name"
                    onChange={handleChange("legal_name")}
                    value={values?.legal_name || ""}
                    error={Boolean(errors.legal_name)}
                    helperText={errors.legal_name}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Box
                    sx={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                    }}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                            Nature of Business
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ width: "100%" }}
                            value={values?.business_type}
                            onChange={handleChange("business_type")}>
                            <FormControlLabel
                                sx={{
                                    width: "100%",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    borderColor: "#EDEDED",
                                    padding: "10px",
                                    m: 0,
                                    borderTopRightRadius: "10px",
                                    borderTopLeftRadius: "10px",
                                }}
                                value="Wholesale Supplier of Packaging Materials"
                                control={<Radio />}
                                label="Wholesale Supplier of Packaging Materials"
                                onChange={handleChange("business_type")}
                            />
                            <FormControlLabel
                                sx={{
                                    width: "100%",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    borderColor: "#EDEDED",
                                    padding: "10px",
                                    m: 0,
                                    borderBottomRightRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                }}
                                value="Manufacturing of Packaging Materials"
                                control={<Radio />}
                                label="Manufacturing of Packaging Materials"
                                onChange={handleChange("business_type")}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
            {values?.business_type ===
                "Manufacturing of Packaging Materials" && (
                <Grid item xs={12} md={12}>
                    <Box
                        sx={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0px",
                        }}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                Customize as per customer requirements
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                sx={{ width: "100%" }}
                                value={values?.business_customize_requirement}
                                onChange={handleChange(
                                    "business_customize_requirement"
                                )}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                    onChange={handleChange(
                                        "business_customize_requirement"
                                    )}
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    onChange={handleChange(
                                        "business_customize_requirement"
                                    )}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
            )}
            <Grid item xs={12} md={12}>
                <Input
                    id="gst_vat_number"
                    name="gst_vat_number"
                    label="GST number"
                    placeholder="Enter GST/VAT Number"
                    onChange={handleChange("gst_vat_number")}
                    value={values?.gst_vat_number || ""}
                    error={Boolean(errors.gst_vat_number)}
                    helperText={errors.legal_name}
                    fullWidth
                />
                <div className="upload_form">
                    <label htmlFor="uploadFile1" className="upload_icon">
                        <span> Upload GST/VAT file as PDF</span>
                        <span>Browse</span>
                    </label>
                    <input
                        type="file"
                        id="uploadFile1"
                        className="hidden"
                        accept="application/pdf"
                        multiple={false}
                        hidden
                        onChange={(e) =>
                            handleFileChange(
                                "gst_vat_file",
                                e,
                                setSelectedFiles,
                                setUploadProgress,
                                handleFileUpload
                            )
                        }
                    />
                </div>
                {selectedFiles.map((file) => (
                    <React.Fragment key={file.name}>
                        {uploadProgress[file.name] !== 100 && (
                            <div className="space-y-8 mt-2">
                                <div className="flex items-center justify-between">
                                    <div className="bg-gray-300 rounded-full w-11/12 h-2">
                                        <div className="w-full bg-neutral-100 rounded-full h-2">
                                            <div
                                                className="bg-blue-600 h-2 rounded-full transition-all"
                                                style={{
                                                    width: `${
                                                        uploadProgress[
                                                            file.name
                                                        ]
                                                    }%`,
                                                }}></div>
                                        </div>
                                    </div>
                                    <p className="text-sm text-gray-500 font-semibold w-1/12 ml-3">
                                        {uploadProgress[file.name]}%
                                    </p>
                                </div>
                            </div>
                        )}
                        {uploadProgress[file.name] === 100 && (
                            <div className="flex items-center justify-between py-3 px-4 my-2 border border-neutral-300 rounded-lg">
                                <div className="flex items-center">
                                    <img src="/images/fileType.svg" alt="" />
                                    <span className="mx-3 text-sm font-medium text-neutral-800">
                                        {file.name}
                                    </span>
                                    {/*  <span className="text-xs text-neutral-500 font-normal w-24">
                                        {(file.size / 1024).toFixed(2)} KB
                                    </span> */}
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
            <Grid item xs={12} md={12}>
                <Box
                    sx={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                    }}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                            Does your business have export license?
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ width: "100%" }}
                            value={values?.have_export_license}
                            onChange={handleChange("have_export_license")}>
                            <FormControlLabel
                                value={"true"}
                                control={<Radio />}
                                label="Yes"
                                onChange={handleChange("have_export_license")}
                            />
                            <FormControlLabel
                                value={"false"}
                                control={<Radio />}
                                label="No"
                                onChange={handleChange("have_export_license")}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
            {(values?.have_export_license == "true" ||
                values?.have_export_license) && (
                <Grid item xs={12} md={12}>
                    <Input
                        id="export_license_number"
                        name="export_license_number"
                        label="Export License number"
                        placeholder="Enter export License number"
                        onChange={handleChange("export_license_number")}
                        value={values?.export_license_number || ""}
                        error={Boolean(errors.export_license_number)}
                        // helperText={errors.}
                        fullWidth
                    />
                    <div className="upload_form">
                        <label htmlFor="uploadFile2" className="upload_icon">
                            <span> Upload Export License file as PDF</span>
                            <span>Browse</span>
                        </label>
                        <input
                            type="file"
                            id="uploadFile2"
                            className="hidden"
                            accept="application/pdf"
                            multiple={false}
                            hidden
                            onChange={(e) =>
                                handleFileChange(
                                    "export_license_file",
                                    e,
                                    setSelectedFilesexportLicense,
                                    setUploadProgressexportLicense,
                                    handleFileUpload
                                )
                            }
                        />
                    </div>
                    {selectedFilesexportLicense.map((file) => (
                        <React.Fragment key={file.name}>
                            {uploadProgressexportLicense[file.name] !== 100 && (
                                <div className="space-y-8 mt-2">
                                    <div className="flex items-center justify-between">
                                        <div className="bg-gray-300 rounded-full w-11/12 h-2">
                                            <div className="w-full bg-neutral-100 rounded-full h-2">
                                                <div
                                                    className="bg-blue-600 h-2 rounded-full transition-all"
                                                    style={{
                                                        width: `${
                                                            uploadProgressexportLicense[
                                                                file.name
                                                            ]
                                                        }%`,
                                                    }}></div>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 font-semibold w-1/12 ml-3">
                                            {
                                                uploadProgressexportLicense[
                                                    file.name
                                                ]
                                            }
                                            %
                                        </p>
                                    </div>
                                </div>
                            )}
                            {uploadProgressexportLicense[file.name] === 100 && (
                                <div className="flex items-center justify-between py-3 px-4 my-2 border border-neutral-300 rounded-lg">
                                    <div className="flex items-center">
                                        <img
                                            src="/images/fileType.svg"
                                            alt=""
                                        />
                                        <span className="mx-3 text-sm font-medium text-neutral-800">
                                            {file.name}
                                        </span>
                                        {/*  <span className="text-xs text-neutral-500 font-normal w-24">
                                            {(file.size / 1024).toFixed(2)} KB
                                        </span> */}
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
            )}
            <Grid item xs={12} md={12}>
                <Box
                    sx={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                    }}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                            Is your business certified ?
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            sx={{ width: "100%" }}
                            value={values?.is_business_certified}
                            onChange={handleChange("is_business_certified")}>
                            <FormControlLabel
                                value={"true"}
                                control={<Radio />}
                                label="Yes"
                                onChange={handleChange("is_business_certified")}
                            />
                            <FormControlLabel
                                value={"false"}
                                control={<Radio />}
                                label="No"
                                onChange={handleChange("is_business_certified")}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Grid>
            {(values?.is_business_certified == "true" ||
                values?.is_business_certified) && (
                <Grid item xs={12} md={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="multi-select-label">
                            Select Options
                        </InputLabel>

                        <Select
                            labelId="multi-select-label"
                            multiple
                            value={selectedCert}
                            onChange={handleChangeSelected}
                            input={<OutlinedInput label="Select Options" />}
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((value) => {
                                        const option =
                                            supplierCertifications.find(
                                                (option) =>
                                                    option.value === value
                                            );
                                        return (
                                            <Chip
                                                key={value}
                                                label={option?.label}
                                            />
                                        );
                                    })}
                                </div>
                            )}>
                            {supplierCertifications.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}>
                                    <Checkbox
                                        checked={isSelected(option.value)}
                                    />
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
            {(values?.is_business_certified == "true" ||
                values?.is_business_certified) && (
                <Grid item xs={12} md={12}>
                    <div className="upload_form">
                        <label htmlFor="uploadFile4" className="upload_icon">
                            <span> Upload Valid Certifications as PDF</span>
                            <span>Browse</span>
                        </label>
                        <input
                            type="file"
                            id="uploadFile4"
                            className="hidden"
                            accept="application/pdf"
                            multiple
                            hidden
                            onChange={(e) =>
                                handleFileChangeMulti(
                                    "docments",
                                    e,
                                    setSelectedFilesCert,
                                    setUploadProgressCert,
                                    handleFileUploadMulti
                                )
                            }
                        />
                    </div>
                    <div className="upload_f_wrapper">
                        {selectedFilesCert?.map((file) => (
                            <React.Fragment key={file.name}>
                                <div className="upload_f">
                                    <p>{file?.name}</p>
                                    <button onClick={() => removecert(file)}>
                                        <IoMdClose />
                                    </button>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </Grid>
            )}
            <Grid item>
                <label
                    htmlFor=""
                    style={{ margin: "0.5rem 0", color: "rgba(0, 0, 0, 0.6)" }}>
                    Select product types you wish to list ?
                </label>
            </Grid>
            <Grid item xs={12} md={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="multi-select-label">
                        Product types
                    </InputLabel>

                    <Select
                        labelId="multi-select-label"
                        multiple
                        value={selectedCategory}
                        onChange={handleChangeSelectedcategory}
                        input={<OutlinedInput label="Select Options" />}
                        renderValue={(selected) => (
                            <div>
                                {selectedCategory.map((value) => {
                                    const option = supplierCategory.find(
                                        (option) => option.value === value
                                    );
                                    return (
                                        <Chip
                                            key={value}
                                            label={option?.label}
                                        />
                                    );
                                })}
                            </div>
                        )}>
                        {supplierCategory.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                <Checkbox
                                    checked={isSelectedCategory(option.value)}
                                />
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
                <div className="upload_form">
                    <label htmlFor="uploadFile5" className="upload_icon">
                        <span> Upload Product Catalog as PDF</span>
                        <span>Browse</span>
                    </label>
                    <input
                        type="file"
                        id="uploadFile5"
                        className="hidden"
                        accept="application/pdf"
                        multiple
                        hidden
                        onChange={(e) =>
                            handleFileChangeMulti(
                                "catalog",
                                e,
                                setselectedCatolog,
                                setUploadProgressCert,
                                handleFileUploadMulti
                            )
                        }
                    />
                </div>
                <div className="upload_f_wrapper">
                    {selectedCatolog?.map((file) => (
                        <React.Fragment key={file.name}>
                            <div className="upload_f">
                                <p>{file?.name}</p>
                                <button onClick={() => removecatalog(file)}>
                                    <IoMdClose />
                                </button>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </Grid>
        </Grid>
    );
};
