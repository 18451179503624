import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, FormHelperText } from "@mui/material";

export default function BasicSelect(props) {
  const {
    id,
    label,
    value,
    name,
    onChange,
    options,
    placeholder,
    size = "small",
    disabled = false,
    error,
    helperText
  } = props;

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "6px" }} >
      <InputLabel sx={{ fontSize: "14px", color: "#454545", fontWeight: "500" }} shrink={false}>{label}</InputLabel>
      <Select
        size={size}
        id={id}
        name={name}
        value={value}
        label={""}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      >
        {options?.map((element, index) => {
          return (
            <MenuItem disabled={element.disabled} key={"select_key_" + label + index} value={element.value}>
              {element.label}
            </MenuItem>
          );
        })}
      </Select>
      {error ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}
    </Box>
  );
}
