import { Grid } from "@mui/material";
import React, { Fragment } from "react";
import { CardSummary } from "../../../components/Common/CardSummary";
import DashboardSkeleton from "../../../components/Common/DashboardSkeleton";
import { isAuth } from "../../../helpers/cookies";
import Breadcrumbs from "../../../components/layout/Breadcrumbs";
import { useGetDashboardCountQuery } from "../../../store/common/commonApis";
import { CertifiedByCardIcon, DashboardCardIcon, ProductTypeImageCardIcon, ProductTypesCardIcon, SupplierTypesCardIcon } from "../../../components/Common/Icons";

const DashboardContainer = () => {
    const userRoles = isAuth().roles;

    const { data, isLoading } = useGetDashboardCountQuery();

    return (
        <Fragment>
            {
                isLoading
                    ?
                    <DashboardSkeleton />
                    :
                    <Grid container spacing={3} pb={3}>
                        <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                            <CardSummary
                                title="Categories"
                                value={data?.totalCategoriesCount || 0}
                                icon={<DashboardCardIcon />}
                            />
                        </Grid>
                        <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                            <CardSummary
                                title="Product Types"
                                value={data?.totalProductTypesCount || 0}
                                icon={<ProductTypesCardIcon />}
                            />
                        </Grid>
                        <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                            <CardSummary
                                title="Supplier Types"
                                value={data?.totalSupplierTypesCount || 0}
                                icon={<SupplierTypesCardIcon />}
                            />
                        </Grid>
                        <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                            <CardSummary
                                title="Certified By"
                                value={data?.totalCertifiedCount || 0}
                                icon={<CertifiedByCardIcon />}
                            />
                        </Grid>
                        <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                            <CardSummary
                                title="Product Type Image"
                                value={`${data?.withImageCount || 0} / ${data?.totalProductTypesCount || 0}`}
                                icon={<ProductTypeImageCardIcon />}
                            />
                        </Grid>
                    </Grid>
            }
        </Fragment>
    );
}

export default DashboardContainer;