import React, { Fragment } from 'react';
import { Grid, Button, ToggleButtonGroup, ToggleButton, Typography, Stack } from '@mui/material';
import CustomizedSwitches from './CustomSwitch';
import { useDisableProductTypesMutation } from '../../store/masters/mastersApis';
import ConfirmationDialog from './ConfirmationDialog';

const FilterTabs = ({ handlePagination, showImage = false, selectedRows, setSelectedRows, type = "products" }) => {
    const [content, setContent] = React.useState('all');
    const [state, setState] = React.useState(true);
    const [image, setImage] = React.useState("all");
    const [handleUpdate] = useDisableProductTypesMutation();
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);

    console.log(confirmationOpen, "confirmationOpen");

    const handleSearch = (val, type) => {
        if (type === 'content' && val !== null) {
            setContent(val);
            handlePagination({
                pageNo: 0,
                limit: 10,
                content: val,
                state,
                image
            })
        } else if (type === 'state' && val !== null) {
            setState(val);
            handlePagination({
                pageNo: 0,
                limit: 10,
                state: val,
                content,
                image
            })
        } else if (type === 'image' && val !== null) {
            setImage(val);
            handlePagination({
                pageNo: 0,
                limit: 10,
                image: val
            })
        }
    }

    const handleDisableAll = () => {
        setConfirmationOpen(true);
    }

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
        setSelectedRows([]);
    };

    const handleConfirmDisable = () => {
        handleUpdate({ ids: selectedRows, status: state });
        setConfirmationOpen(false);
        setSelectedRows([]);
    };

    return (
        <Grid container gap={10} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
            <Grid item display={"flex"} flexDirection={"row"} gap={1}>
                {
                    showImage ? (
                        <Grid item display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                            <Typography variant="subtitle2" color="textPrimary">Content: </Typography>
                            <ToggleButtonGroup
                                color='primary'
                                value={image}
                                exclusive
                                onChange={(e, val) => handleSearch(val, 'image')}
                                aria-label="image"
                                size='small'
                            >
                                <ToggleButton value="all" aria-label="all" sx={{ px: 2 }}>
                                    All
                                </ToggleButton>
                                <ToggleButton value="with" aria-label="with image" sx={{ px: 2 }}>
                                    With Image
                                </ToggleButton>
                                <ToggleButton value="without" aria-label="without image" sx={{ px: 2 }}>
                                    W/o Image
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    ) : (
                        <Grid container gap={10}>
                            <Grid item display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                                <Typography variant="subtitle2" color="textPrimary">Content: </Typography>
                                <ToggleButtonGroup
                                    color='primary'
                                    value={content}
                                    exclusive
                                    onChange={(e, val) => handleSearch(val, 'content')}
                                    aria-label="content"
                                    size='small'
                                >
                                    <ToggleButton value="all" aria-label="all" sx={{ px: 2 }}>
                                        All
                                    </ToggleButton>
                                    <ToggleButton value="with" aria-label="with content" sx={{ px: 2 }}>
                                        With Content
                                    </ToggleButton>
                                    <ToggleButton value="without" aria-label="without content" sx={{ px: 2 }}>
                                        W/o Content
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                                <Typography variant="subtitle2" color="textPrimary">State: </Typography>
                                <ToggleButtonGroup
                                    color='primary'
                                    value={state}
                                    exclusive
                                    onChange={(e, val) => handleSearch(val, 'state')}
                                    aria-label="state"
                                    size='small'
                                >
                                    <ToggleButton value={true} aria-label="active" sx={{ px: 2 }}>
                                        Active
                                    </ToggleButton>
                                    <ToggleButton value={false} aria-label="disabled" sx={{ px: 2 }}>
                                        Disabled
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
            {
                type === "products" && selectedRows.length > 0 && (
                    <Grid item display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"} >
                        <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" >
                            {state ? "Disable All" : "Enable All"}
                        </Typography>
                        <CustomizedSwitches checked={confirmationOpen} onChange={handleDisableAll} />
                    </Grid>
                )
            }

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmDisable}
                title={!state ? "Are you sure you want to enable selected row?" : "Are you sure you want to disable selected row?"}
                confirmButtonTitle={!state ? "Yes, Enable" : "Yes, Disable"}
            />
        </Grid>
    );
};

export default FilterTabs;
