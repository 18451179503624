import { get } from "lodash-es";

export const productsParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        if (response.results);

        if (response.status === "pending") {
            response.results = response.results.map((row, key) => {
                return {
                    id: get(row, "id", ""),
                    unique_id: get(row, "unique_id", ""),
                    user: get(row, "user", null),
                    createdAt: get(row, "createdAt", new Date()),
                    Category_name: get(row, "Category_name", ""),
                    legal_name: get(row, "legal_name", "Devarakonda Technologies Pvt. Ltd"),
                    business_type: get(row, "business_type", "Manufacture"),
                }
            })
        } else {
            response.results = response.results.map((row, key) => {
                return {
                    id: get(row, "id", ""),
                    unique_id: get(row, "unique_id", ""),
                    product_name: get(row, "product_name", ""),
                    Category_name: get(row, "productType.category.name", ""),
                    user: get(row, "supplier.user", null),
                    supplier: get(row, "supplier", null),
                    images: get(row, "images", null),
                    description: get(row, "description", null),
                    hasImages: get(row, "hasImages", null),
                    productTypeId: get(row, "productTypeId", null),
                    legal_name: get(row, "supplier.legal_name", "Devarakonda Technologies Pvt. Ltd"),

                }
            })
        }

        return response;
    } catch (error) {
        throw new Error(error);
    }
}

export const productsUniqueParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }

        if (response) {
            // Transform file_mappings into an object where each file_type is a key
            const fileMappingsTransformed = response.file_mappings.reduce((acc, file) => {
                if (!acc[file.file_type]) {
                    acc[file.file_type] = [];
                }
                acc[file.file_type].push(file.file_url);
                return acc;
            }, {});

            return {
                ...response,
                ...fileMappingsTransformed
            };
        }

        return response;
    } catch (error) {
        throw new Error(error);
    }
};

export const supplierProductsParser = (response, dispatch) => {
    try {
        if (response?.result) {
            response = response.result;
        }
        if (!response) {
            return [];
        }
        // if (response.results)
        //     response.results = response.results.map((row, key) => {
        //         return {
        //             id: get(row, "id", ""),
        //             unique_id: get(row, "unique_id", ""),
        //             user: get(row, "user", null),
        //             createdAt: get(row, "createdAt", new Date()),
        //             legal_name: get(row, "legal_name", "Devarakonda Technologies Pvt. Ltd"),
        //             business_type: get(row, "business_type", "Manufacture"),
        //         }
        //     })

        return response;
    } catch (error) {
        throw new Error(error);
    }
};