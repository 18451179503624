import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeMastersInitialStateSupplierTypes, handlePaginationStateSupplierTypes } from "../../../store/masters/mastersSlice";

const mapStateToProps = (state) => {
    return {
        // location
        formType: state.masters.formTypeSupplierTypes,
        initialValues: state.masters.initialValuesSupplierTypes,
        showDrawer: state.masters.showDrawerSupplierTypes,
        pagination: state.masters.paginationSupplierTypes,
    };
};

const mapDispatch = {
    changeSupplierTypesInitialState: changeMastersInitialStateSupplierTypes,
    handlePaginationState: handlePaginationStateSupplierTypes,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;