// mui components
import IconMenu from '@mui/icons-material/Menu';
import { Avatar, Box, IconButton, Stack } from '@mui/material';

// helpers
import { useTheme } from '@emotion/react';
import Notification from './Notification';
import Profile from './Profile';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'none' } }} >
                <IconButton sx={{ borderRadius: '12px', overflow: 'hidden' }} onClick={handleLeftDrawerToggle}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                        }}
                        color="inherit"
                    >
                        <IconMenu stroke={1.5} size="1.3rem" />
                    </Avatar>
                </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={1}
            >
                <Profile />
            </Stack>
        </>
    );
};

export default Header;