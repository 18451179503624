import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from "../../helpers/constants";
import { getCookie, signout } from "../../helpers/cookies";
import { productsParser, productsUniqueParser, supplierProductsParser } from './productsParser';

const customFetchBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getCookie("paqej_admin_token");
        if (token) {
            headers.set('Authorization', `BEARER ${token}`)
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await customFetchBaseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 403) {
        signout(() => { window.location.href = "/login"; });
    }

    return result;
};

const productsApis = createApi({
    reducerPath: 'productsApis',
    baseQuery: baseQueryWithReauth,
    keepUnusedDataFor: 0,
    tagTypes: ["Suppliers", "ProductsSupplier"],
    endpoints: build => ({
        getProductsById: build.query({
            query: (params) => ({
                url: "/users/products",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => productsUniqueParser(response),
            providesTags: ['Suppliers'],
            keepUnusedDataFor: 5,
        }),
        getProductsBySuppliers: build.query({
            query: (params) => ({
                url: "/products/suppliers",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => supplierProductsParser(response),
            providesTags: ['ProductsSupplier'],
            keepUnusedDataFor: 5,
        }),
        getProducts: build.query({
            query: (params) => ({
                url: "/products/list",
                method: "GET",
                params: params
            }),
            transformResponse: (response) => productsParser(response),
            providesTags: ['ProductsSupplier'],
            keepUnusedDataFor: 5,
        }),
        createUpdateProducts: build.mutation({
            query: (payload) => ({
                url: "/products",
                method: "POST",
                body: payload
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['ProductsSupplier'],
        }),
        updateProducts: build.mutation({
            query: (payload) => ({
                url: "/products",
                method: "PUT",
                body: payload
            }),
            transformResponse: (response) => response,
            invalidatesTags: ['ProductsSupplier'],
        }),
    }),
});


export const {
    useGetProductsByIdQuery,
    useGetProductsQuery,
    useGetProductsBySuppliersQuery,
    useCreateUpdateProductsMutation,
    useUpdateProductsMutation
} = productsApis;
export default productsApis