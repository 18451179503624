import React, { useState } from "react";
import {
    TableCell,
    TableRow,
    Typography,
    Stack,
    TextField,
    Button,
    Select,
    MenuItem,
} from "@mui/material";
import TableCommon from "../../Common/Table";
// import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import TableSkeleton from "../../Common/TableSkeleton";
import {
    Box,
    Checkbox,
    IconButton,
    Paper,
    TableFooter,
    TablePagination,
    TableSortLabel,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { useUpdateCategoryMutation } from "../../../store/masters/mastersApis";
import VerticalMenu from "../../Common/VerticleAction";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import { PencilIcon } from "../../Common/Icons";
import CustomizedSwitches from "../../Common/CustomSwitch";

const CategoryTableComponent = ({
    loading,
    fetching = false,
    count,
    data,
    setAllData,
    editState,
    pagination,
    handlePagination,

    setShowAlert,
    setWarningItems,
}) => {
    const [handleUpdate] = useUpdateCategoryMutation();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
    // const [AllData, setAllData] = useState(data);
    const handleDisable = (row) => {
        setCategoryIdToDelete(row);
        setConfirmationOpen(true);
    };

    const handleConfirmDisable = () => {
        handleUpdate({
            id: categoryIdToDelete?.id,
            name: categoryIdToDelete?.name,
            icategoryId: categoryIdToDelete?.icategoryId,
            disabled: !categoryIdToDelete?.disabled,
        });
        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };
    let columns = [
        {
            title: "ID No.",
            sort: false,
            // width: "10%",
        },
        {
            title: "Category",
            field: "name",
            sort: false,
            // width: "10%",
        },

        {
            title: "Priority",
            field: "priority",
            sort: true,
            width: "8%",
        },
        {
            title: "Display",
            field: "homepagedisplay",
            sort: false,
            width: "15%",
        },
        {
            title: "Created by & date",
            field: "createdAt",
            sort: false,
            // width: "20%",
        },
    ];
    const changeHandler = (key, input, id) => {
        let AllData = JSON.parse(JSON.stringify(data));
        let Selected = AllData.findIndex((item) => item.id == id);
        if (Selected != -1) {
            AllData[Selected][key] = Number(input);
        }

        setShowAlert(false);
        setWarningItems([]);
        setAllData(AllData);
    };
    const renderTableData =
        !loading && data && data.length > 0 ? (
            data.map(function (row, index) {
                return (
                    <TableRow
                        key={index}
                        sx={{
                            "td, th": { border: 0, padding: "10px" },
                            ...(row?.disabled && { opacity: 0.4 }),
                        }}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>
                            {row?.name}
                            {row?.disabled ? (
                                <Button
                                    sx={{ ml: 2 }}
                                    disabled
                                    size="small"
                                    variant="contained">
                                    Disabled
                                </Button>
                            ) : null}
                        </TableCell>
                        <TableCell>
                            {editState ? (
                                <input
                                    value={row?.priority}
                                    label="priority"
                                    variant="outlined"
                                    onChange={(e) =>
                                        changeHandler(
                                            "priority",
                                            e.target.value,
                                            row.id
                                        )
                                    }
                                    style={{
                                        width: "4rem",
                                        height: "2rem",
                                        border: "1px solid #ccc",
                                        textIndent: "0.3rem",
                                        fontSize: "0.8rem",
                                    }} // Adjust the width as needed
                                />
                            ) : (
                                row?.priority
                            )}
                        </TableCell>
                        <TableCell>
                            {editState ? (
                                <Select
                                    value={row.homepagedisplay}
                                    sx={{ width: "6rem", height: "2rem" }}
                                    onChange={(e) =>
                                        changeHandler(
                                            "homepagedisplay",
                                            e.target.value,
                                            row.id
                                        )
                                    }>
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={2}>No</MenuItem>
                                </Select>
                            ) : row?.homepagedisplay == 1 ? (
                                "Yes"
                            ) : (
                                "No"
                            )}
                        </TableCell>
                        <TableCell>
                            <Typography variant="body2" fontFamily={500}>
                                {row?.createdBy?.name}
                            </Typography>
                            <Typography variant="caption">
                                {handleDateTimeDefault(row?.createdAt)}
                            </Typography>
                        </TableCell>
                        {/*          <TableCell component="th" scope="row">
                            <VerticalMenu>
                                <Typography
                                    sx={{
                                        gap: 2,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    variant="body2"
                                    onClick={() => edit(row, "Edit")}>
                                    <PencilIcon /> Update
                                </Typography>
                                <Stack
                                    direction="row"
                                    width={"100%"}
                                    justifyContent={"space-between"}>
                                    <Typography
                                        sx={{
                                            gap: 2,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        variant="body2">
                                        {row?.disabled ? "Enable" : "Disable"}
                                    </Typography>
                                    <CustomizedSwitches
                                        checked={confirmationOpen}
                                        onChange={() => handleDisable(row)}
                                    />
                                </Stack>
                            </VerticalMenu>
                        </TableCell> */}
                    </TableRow>
                );
            })
        ) : (
            <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
                <TableCell colSpan={5} align="center">
                    Data not found
                </TableCell>
            </TableRow>
        );

    const paginationRequest = ({
        pageNo,
        limit,
        sortBy,
        sortField,
        keywords,
    }) => {
        handlePagination({
            pageNo: pageNo !== undefined ? pageNo : pagination.pageNo,
            limit: limit !== undefined ? limit : pagination.limit,
            sortBy: sortBy !== undefined ? sortBy : pagination.sortBy,
            sortField:
                sortField !== undefined ? sortField : pagination.sortField,
            keywords: keywords || pagination.keywords,
        });
    };

    const handleRequestSort = (property) => {
        const isAsc =
            pagination.sortField === property && pagination.sortBy === "ASC";
        paginationRequest({
            sortBy: isAsc ? "DESC" : "ASC",
            sortField: property,
        });
    };

    return (
        <>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead>
                            <TableRow
                                sx={{
                                    th: { border: 0 },
                                    backgroundColor: "#F8F8F8",
                                }}>
                                {columns.map((column, index) => {
                                    if (column.hide) {
                                        return false;
                                    }
                                    return (
                                        <TableCell
                                            align="left"
                                            sx={{
                                                p: 1.4,
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                minWidth: column.minWidth,
                                                width: column?.width,
                                            }}
                                            key={index}
                                            sortDirection={
                                                pagination.sortField ===
                                                column.field
                                                    ? pagination.sortBy ===
                                                      "ASC"
                                                        ? "asc"
                                                        : "desc"
                                                    : false
                                            }>
                                            {column.sort ? (
                                                <TableSortLabel
                                                    active={
                                                        pagination.sortField ===
                                                        column.field
                                                    }
                                                    direction={
                                                        pagination.sortField ===
                                                        column.field
                                                            ? pagination.sortBy ===
                                                              "ASC"
                                                                ? "asc"
                                                                : "desc"
                                                            : "asc"
                                                    }
                                                    onClick={() =>
                                                        handleRequestSort(
                                                            column.field
                                                        )
                                                    }>
                                                    {column.title}
                                                </TableSortLabel>
                                            ) : (
                                                column.title
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableSkeleton rows={columns} />
                            ) : (
                                renderTableData
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmDisable}
                title={
                    categoryIdToDelete?.disabled
                        ? "Are you sure you want to enable selected row?"
                        : "Are you sure you want to disable selected row?"
                }
                confirmButtonTitle={
                    categoryIdToDelete?.disabled
                        ? "Yes, Enable"
                        : "Yes, Disable"
                }
            />
        </>
    );
};

export default CategoryTableComponent;
