import { format } from "date-fns";

export const handleDateTimeDefault = (date, formats = "do MMM yyyy, h:mm a") => {
    try {
        const formattedDate = format(new Date(date), formats).replace(/\b(\d{1,2})(th)\b/g, (_, day, th) => `${day}<sup>${th}</sup>`);
        return <span dangerouslySetInnerHTML={{ __html: formattedDate }}></span>
    } catch (e) {
        return "Invalid Date";
    }
}


export const parseIsoDefault = (date) => {
    try {
        return new Date(date)
    } catch (e) {
        return "Invalid Date";
    }
}

export const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    var filtered = lines.filter(function (el) {
        return el !== "";
    });
    const headers = lines[0].split(",");
    const result = [];

    for (let i = 1; i < filtered.length; i++) {
        const obj = {};
        const currentLine = filtered[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j].trim().toLowerCase()] = currentLine[j] ? currentLine[j].trim() : "";
        }

        result.push(obj);
    }

    return result;
};

export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const calculateAge = (birthday) => {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function hasChildren(item) {
    const { items: children } = item;

    if (children === undefined) {
        return false;
    }

    if (children.constructor !== Array) {
        return false;
    }

    if (children.length === 0) {
        return false;
    }

    return true;
}


export function calculatePlanAmount(plan, family) {
    let totalAmount = plan?.amount || 0;

    if (family && family.length > 0) {
        family.map((obj) => {
            if (obj.is_dependent) {
                totalAmount += plan?.dependent_member_price || 0
            } else {
                totalAmount += plan?.non_dependent_member_price || 0
            }
            return totalAmount;
        })
    }

    return totalAmount;
}

export const readFirstArray = (array) => {
    try {
        return array[0];
    } catch (e) {
        return array;
    }
}

export const generatePermaLink = (string) => {
    return string.replace(/\s+/g, '-').toLowerCase();
}

export function generateBatchName(sportName, batchCode) {
    // Extract the first four characters of the sport name
    const sportPrefix = sportName.substring(0, 4);

    // Replace any spaces with underscores
    const formattedSportPrefix = sportPrefix.replace(/ /g, '_');

    // Combine the formatted sport prefix and the batch code
    const batchName = `${formattedSportPrefix}_${batchCode}`;

    return batchName;
}

export const getMonthNameByNumber = (monthNumber) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return monthNames[monthNumber - 1];
}