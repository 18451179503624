import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";

import CategoryTableComponent from "../../../components/admin/Homepage/CategoryTable";
import { CategoryAddDrawer } from "../../../components/admin/Homepage/CategoryAddDrawer";
import { Alert } from "@mui/material";

import {
    useGetCategoryHomePageListQuery,
    useEditpriorityCategoryMutation,
} from "../../../store/masters/mastersApis";
import { defaultPaginate } from "../../../helpers/constants";
import { AddOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import { setSnackBar } from "../../../store/common/commonSlice";

const CategoryManagerContainer = (props) => {
    const [edit, setEdit] = useState(false);
    const [AllData, setAllData] = useState([]);
    const [warningItems, setWarningItems] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const dispatch = useDispatch();
    const [updateCategory, { isLoading: updateCategoryLoading }] =
        useEditpriorityCategoryMutation();
    const { showDrawer, formType, initialValues, pagination } = props;
    const {
        isLoading,
        data: category,
        isFetching,
    } = useGetCategoryHomePageListQuery(pagination);

    const handleAddCategory = () => {
        props.changeCategoryInitialState({
            showDrawer: true,
            formType: "Add",
            initialValues: null,
        });
    };

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, []);
    useEffect(() => {
        if (!isLoading) {
            setAllData(category?.results);
            // console.log(category);
        }
    }, [isLoading]);

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    };

    const handleDrawerClose = () => {
        props.changeCategoryInitialState({
            showDrawer: false,
            formType: "",
            initialValues: null,
        });
    };

    const handleCategoryEdit = (data, type) => {
        let payload = {
            id: data.id,
            name: data.name,
            icategoryId: data.icategoryId,
            homepagedisplay: data?.homepagedisplay,
            priority: data?.priority,
        };
        props.changeCategoryInitialState({
            showDrawer: true,
            formType: type,
            initialValues: payload,
        });
    };
    const EditHandler = async () => {
        if (!edit) {
            setEdit(true);
        } else {
            const priorityMap = {};

            // Group items by priority
            AllData.forEach((item) => {
                if (priorityMap[item.priority]) {
                    priorityMap[item.priority].push(item.name);
                } else {
                    priorityMap[item.priority] = [item.name];
                }
            });

            // Find priorities with more than one item
            const duplicates = Object.values(priorityMap).filter(
                (group) => group.length > 1
            );

            if (duplicates.length > 0) {
                setWarningItems(duplicates.flat());
                setShowAlert(true);
                return;
            }
            await updateCategory(AllData)
                .unwrap()
                .then((res) => console.log(res));
            dispatch(
                setSnackBar({
                    open: true,
                    message: "Category updated successfully",
                    severity: "success",
                })
            );
            setEdit(false);
            console.log(AllData);
        }
    };
    return (
        <Stack spacing={2}>
            <Grid
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
                item
                xs={6}>
                <Typography variant="h6">
                    Display Categories{" "}
                    <span
                        style={{
                            color: "gray",
                            fontSize: "10px",
                            verticalAlign: "super",
                        }}>
                        ({category?.total || 0})
                    </span>{" "}
                </Typography>
                <Button
                    loading={updateCategoryLoading}
                    onClick={EditHandler}
                    style={{
                        color: "white",
                        backgroundColor: "red",
                        cursor: "pointer",
                        border: "none",
                        padding: "0.5rem 1rem",
                        fontSize: "0.9rem",
                        borderRadius: "10px",
                    }}>
                    {edit ? "Save" : "Edit"}
                </Button>
            </Grid>

            <Grid container>
                {showAlert && warningItems.length > 0 && (
                    <Alert severity="warning">
                        The following Category have the same priority:{" "}
                        {warningItems.join(", ")}
                    </Alert>
                )}
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}>
                        <CategoryTableComponent
                            edit={(val, type) => handleCategoryEdit(val, type)}
                            loading={isLoading}
                            fetching={isFetching}
                            editState={edit}
                            count={category?.total}
                            data={AllData || []}
                            setAllData={setAllData}
                            setShowAlert={setShowAlert}
                            setWarningItems={setWarningItems}
                            pagination={pagination}
                            handlePagination={(val) => handlePagination(val)}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <CategoryAddDrawer
                show={showDrawer}
                close={handleDrawerClose}
                formType={formType}
                initialValues={initialValues}
            />
        </Stack>
    );
};

export default CategoryManagerContainer;
