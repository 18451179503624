import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { getPhoneCode } from 'libphonenumber-js';

// Get the phone code using libphonenumber-js
const CountryCodeIcon = ({ countryCode = "IN", style, showNumber = false, ...props }) => {
    const phoneCode = getPhoneCode(countryCode) || "";

    return (
        <div style={{ display: 'flex', alignItems: 'center', ...style }}>
            <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{
                    width: '2em',
                    height: '2em',
                    marginRight: 10,
                }}
                {...props}
            />
            {
                showNumber && (<span style={{ fontSize: '1em', marginRight: 5 }}>{`+${phoneCode}`}</span>)
            }

        </div>
    );
};

export default CountryCodeIcon;
