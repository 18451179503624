import React, { useEffect, useState } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";

import { defaultPaginate } from "../../../helpers/constants";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import { capitalizeFirstLetter } from "../../../helpers/utils";
import SuppliersTableComponent from "../../../components/subadmin/suppliers-manager/SuppliersTable";
import { useGetProductsQuery } from "../../../store/products/productsApis";
import ProductsTableComponent from "../../../components/subadmin/products/ProductsTable";
import Button from "../../../components/Common/Button";
import { AddOutlined } from "@mui/icons-material";
import { ProductAddDrawer } from "../../../components/subadmin/products/ProductAddDrawer";

const ProductsManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination, params } = props;
    const [selectedRows, setSelectedRows] = React.useState([]);
    const {
        isLoading,
        data: products,
        isFetching,
    } = useGetProductsQuery({ ...pagination, status: params?.status });

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [params]);
    console.log(props);
    const handlePagination = (setting) => {
        console.log(setting, "setting");
        props.handlePaginationState(setting);
        setSelectedRows([]);
    };

    const handleDrawerClose = () => {
        props.changeInitialState({
            showDrawer: false,
            formType: "",
            initialValues: null,
        });
    };

    const handleProductEdit = (data, type) => {
        if (type === "drawer") {
            props.changeInitialState({
                showDrawer: true,
                formType: "edit",
                initialValues: data,
            });
        } else {
            props.navigate(`/products/${params.status}/${data?.unique_id}`);
        }
    };

    return (
        <Stack spacing={2}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6">
                        {`${capitalizeFirstLetter(params?.status)} Products`}
                        {/* <span style={{ color: "gray", fontSize: "10px", verticalAlign: "super" }}>({0})</span> */}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{ alignSelf: "center" }}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}>
                    <SearchRecords
                        handlePagination={handlePagination}
                        pagination={pagination}
                        placeholder={"Search"}
                    />
                    {params.status === "active" && (
                        <Button
                            disableElevation
                            fullWidth={false}
                            variant="contained"
                            sx={{
                                borderRadius: "8px",
                                marginLeft: 2,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                fontSize: "16px",
                            }}
                            onClick={() =>
                                props.navigate(
                                    `/products/${params.status}/add-product`
                                )
                            }>
                            <AddOutlined />
                            Add Product
                        </Button>
                    )}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}>
                        {params.status === "pending" ? (
                            <SuppliersTableComponent
                                edit={(val, type) =>
                                    handleProductEdit(val, type)
                                }
                                loading={isLoading}
                                fetching={isFetching}
                                count={products?.total}
                                data={products?.results || []}
                                pagination={pagination}
                                handlePagination={(val) =>
                                    handlePagination(val)
                                }
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        ) : (
                            <ProductsTableComponent
                                edit={(val, type) =>
                                    handleProductEdit(val, type)
                                }
                                loading={isLoading}
                                fetching={isFetching}
                                count={products?.total}
                                data={products?.results || []}
                                pagination={pagination}
                                handlePagination={(val) => {
                                    console.log(val);
                                    handlePagination(val);
                                }}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <ProductAddDrawer
                params={params?.status}
                show={showDrawer}
                close={handleDrawerClose}
                formType={formType}
                initialValues={initialValues}
            />
        </Stack>
    );
};

export default ProductsManagerContainer;
