import React, { Fragment, useCallback, useState } from "react";
import {
    Typography,
    Button,
    TablePagination,
    Grid,
    Card,
    CardContent,
    Box,
    Modal,
    Fade,
    Backdrop,
    Dialog,
    FormHelperText,
} from "@mui/material";
import { useUpdateProductTypesMutation } from "../../../store/masters/mastersApis";
import PhotoIcon from '@mui/icons-material/Photo';
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from '@mui/system';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from "../../Common/cropImage";
import { useHandleImageUploadMutation } from "../../../store/common/commonApis";
import { setSnackBar } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#F5F5F5',
    borderRadius: '8px',
    border: '1px solid #EDEDED',
    cursor: 'pointer',
    position: 'relative',
    '&:hover .hover-button': {
        opacity: 1,
    },
}));

const HoverButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'white',
    opacity: 0,
    transition: 'opacity 0.3s',
    color: theme.palette.secondary.darktext,
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    gap: "5px",
    padding: '5px 10px',
}));

const ProductImageTableComponent = ({ loading, fetching = false, count, data, edit, pagination, handlePagination }) => {
    const [handleUpdate] = useUpdateProductTypesMutation();
    const [uploadImage] = useHandleImageUploadMutation();

    const dispatch = useDispatch();

    const [isUploading, setIsUploading] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageData, setSelectedImageData] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onload = () => {
        //         setSelectedImage(reader.result);
        //         setModalOpen(true);
        //     };
        //     reader.readAsDataURL(file);
        // }
    // };

    // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //     setCroppedAreaPixels(croppedAreaPixels);
    // }, []);

    const handleUpload = async (event) => {
        try {
            // const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels, 148, 127);
            // const blob = await fetch(croppedImage.basestring).then((res) => res.blob());
            const file = event.target.files[0];
            setIsUploading(true);
            const formData = new FormData();
            formData.append("image", file, 'image.png');
            let result = await uploadImage(formData);
            if (result && result?.data) {
                const s3_url = result?.data?.S3?.Location;
                if (s3_url) {
                    // console.log(selectedImageData, "selectedImageData");
                    await handleUpdate({ id: selectedImageData.id, name: selectedImageData.name, categoryId: selectedImageData.categoryId, image: s3_url }).unwrap();
                    setSelectedImage(null);
                    // setModalOpen(false);
                }
            }
            // setModalOpen(false);
            setIsUploading(false);
        } catch (error) {
            dispatch(setSnackBar({
                open: true,
                message: error?.data?.message || error.message,
                severity: "error",
            }))
        }
    };

    const paginationRequest = ({ pageNo, limit, sortBy, sortField, keywords }) => {
        handlePagination({
            pageNo: pageNo !== undefined ? pageNo : pagination.pageNo,
            limit: limit !== undefined ? limit : pagination.limit,
            sortBy: sortBy !== undefined ? sortBy : pagination.sortBy,
            sortField: sortField !== undefined ? sortField : pagination.sortField,
            keywords: keywords || pagination.keywords,
        })
    }

    const chooseImage = (id) => {
        setSelectedImageData(id);
        document.getElementById('fileInput').click()
    }

    const RenderImageTable = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <StyledCard>
                        <Box sx={{ p: '35px 25px 35px 25px', textAlign: 'center' }}>

                            {
                                row?.image ? (
                                    <Box sx={{ maxHeight: "127px" }}>
                                        <img src={row?.image} width="148" height="127" alt={row?.name} />
                                    </Box>
                                ) : (
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} gap={3} alignItems={"center"} sx={{ maxHeight: "127px" }}>
                                        <PhotoIcon sx={{ color: "gray", width: "24px", height: "24px" }} />
                                        <Button size="small" variant="contained" color="background" fullWidth={false} onClick={() => chooseImage(row)}>Upload Image</Button>
                                        {/* <FormHelperText sx={{ color: "red", textAlign: "center" }}>File size too large. Minimum size 50 Kb</FormHelperText> */}
                                    </Box>
                                )
                            }
                        </Box>
                        <CardContent sx={{ backgroundColor: '#FFFFFF', textAlign: 'left', p: '14px 16px 14px 16px !important' }}>
                            <Typography gutterBottom variant="h5" component="div" sx={{ mb: 0, fontSize: '12px', fontWeight: '500' }}>
                                {row?.name}
                            </Typography>
                        </CardContent>
                        {
                            row?.image ? (
                                <HoverButton size="small" className="hover-button" onClick={() => chooseImage(row)}>
                                    <PhotoIcon />
                                    Update
                                </HoverButton>
                            ) : null
                        }
                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleUpload}
                        />

                    </StyledCard>
                </Grid>
            )
        })
    ) : null;

    return (
        <>
            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: count }]}
                count={count}
                rowsPerPage={pagination.limit}
                page={pagination.pageNo}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                onPageChange={(e, newNo) => paginationRequest({ pageNo: newNo })}
                onRowsPerPageChange={(e) => paginationRequest({ limit: e.target.value })}
                ActionsComponent={TablePaginationActions}
            />
            <Grid container sx={{ width: '100%' }} spacing={2}>
                {RenderImageTable}
            </Grid>
            {/* <Dialog
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                maxWidth="md"
                fullWidth={true}
            >
                <Fade in={modalOpen}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4, bgcolor: 'background.paper', borderRadius: 1 }}>
                        {selectedImage && (
                            <Box sx={{ width: '100%', height: 400, position: 'relative' }}>
                                <Cropper
                                    image={selectedImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </Box>
                        )}
                        <Button variant="contained" color="primary" onClick={handleUpload} sx={{ mt: 2 }} disabled={isUploading}>
                            Upload
                        </Button>
                    </Box>
                </Fade>
            </Dialog> */}
        </>
    );
};

export default ProductImageTableComponent;
