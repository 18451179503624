import { createSlice } from "@reduxjs/toolkit";

const defaultFormValueICategory = {
    name: "",
}

const defaultFormValueCategory = {
    icategoryId: "",
    name: "",
    homepagedisplay: "",
    priority: ""
}

const defaultFormValueProductTypes = {
    categoryId: "",
    name: "",
    content: "",
}

const defaultFormValueSupplierTypes = {
    name: "",
}

const defaultFormValueCertified = {
    name: "",
}

const initialState = {
    // icategory state
    showDrawerICategory: false,
    formTypeICategory: "",
    initialValuesICategory: { ...defaultFormValueICategory },
    paginationICategory: {
        pageNo: 0,
        limit: 10,
        sortBy: "DESC",
        sortField: "createdAt",
        keywords: ""
    },
    // category state
    showDrawerCategory: false,
    formTypeCategory: "",
    initialValuesCategory: { ...defaultFormValueCategory },
    paginationCategory: {
        pageNo: 0,
        limit: 10,
        sortBy: "DESC",
        sortField: "createdAt",
        keywords: ""
    },
    // product types state
    showDrawerProductTypes: false,
    formTypeProductTypes: "",
    initialValuesProductTypes: { ...defaultFormValueProductTypes },
    paginationProductTypes: {
        pageNo: 0,
        limit: 10,
        sortBy: "DESC",
        sortField: "createdAt",
        keywords: ""
    },
    // supplier types state
    showDrawerSupplierTypes: false,
    formTypeSupplierTypes: "",
    initialValuesSupplierTypes: { ...defaultFormValueSupplierTypes },
    paginationSupplierTypes: {
        pageNo: 0,
        limit: 10,
        sortBy: "DESC",
        sortField: "createdAt",
        keywords: ""
    },
    // certified state
    showDrawerCertified: false,
    formTypeCertified: "",
    initialValuesCertified: { ...defaultFormValueCertified },
    paginationCertified: {
        pageNo: 0,
        limit: 10,
        sortBy: "DESC",
        sortField: "createdAt",
        keywords: ""
    },
};

export const mastersSlice = createSlice({
    name: "masters",
    initialState,
    reducers: {
        // icategory state
        changeMastersInitialStateICategory: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawerICategory = showDrawer;
            if (formType)
                state.formTypeICategory = formType;
            if (initialValues)
                state.initialValuesICategory = initialValues;
            if (!initialValues)
                state.initialValuesICategory = defaultFormValueICategory;
        },
        handlePaginationStateICategory: (state, action) => {
            const { payload } = action;
            state.paginationICategory = payload;
        },
        // category state
        changeMastersInitialStateCategory: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawerCategory = showDrawer;
            if (formType)
                state.formTypeCategory = formType;
            if (initialValues)
                state.initialValuesCategory = initialValues;
            if (!initialValues)
                state.initialValuesCategory = defaultFormValueCategory;
        },
        handlePaginationStateCategory: (state, action) => {
            const { payload } = action;
            state.paginationCategory = payload;
        },
        // product types state
        changeMastersInitialStateProductTypes: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawerProductTypes = showDrawer;
            if (formType)
                state.formTypeProductTypes = formType;
            if (initialValues)
                state.initialValuesProductTypes = initialValues;
            if (!initialValues)
                state.initialValuesProductTypes = defaultFormValueProductTypes;
        },
        handlePaginationStateProductTypes: (state, action) => {
            const { payload } = action;
            state.paginationProductTypes = payload;
        },
        // supplier types state
        changeMastersInitialStateSupplierTypes: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawerSupplierTypes = showDrawer;
            if (formType)
                state.formTypeSupplierTypes = formType;
            if (initialValues)
                state.initialValuesSupplierTypes = initialValues;
            if (!initialValues)
                state.initialValuesSupplierTypes = defaultFormValueSupplierTypes;
        },
        handlePaginationStateSupplierTypes: (state, action) => {
            const { payload } = action;
            state.paginationSupplierTypes = payload;
        },
        // certified state
        changeMastersInitialStateCertified: (state, action) => {
            const { showDrawer, formType, initialValues } = action.payload;

            state.showDrawerCertified = showDrawer;
            if (formType)
                state.formTypeCertified = formType;
            if (initialValues)
                state.initialValuesCertified = initialValues;
            if (!initialValues)
                state.initialValuesCertified = defaultFormValueCertified;
        },
        handlePaginationStateCertified: (state, action) => {
            const { payload } = action;
            state.paginationCertified = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    changeMastersInitialStateICategory, handlePaginationStateICategory,
    changeMastersInitialStateCategory, handlePaginationStateCategory,
    changeMastersInitialStateProductTypes, handlePaginationStateProductTypes,
    changeMastersInitialStateSupplierTypes, handlePaginationStateSupplierTypes,
    changeMastersInitialStateCertified, handlePaginationStateCertified
} = mastersSlice.actions;

export default mastersSlice;
