const LogoSection = ({ theme }) => {
    return (
        <img
            style={{
                width: "50%",
            }}
            src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo.svg`}
            alt='Logo'
        />
    )
}

export default LogoSection;