import React, { Fragment, useEffect, useState } from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Grid,
    InputLabel,
    Link,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { defaultPaginate } from "../../../helpers/constants";

import { SearchRecords } from "../../../components/Common/SearchRecords";
import { capitalizeFirstLetter } from "../../../helpers/utils";
import { useGetSupplierByIdQuery } from "../../../store/suppliers/suppliersApis";
import DashboardSkeleton from "../../../components/Common/DashboardSkeleton";
import { NavigateNext } from "@mui/icons-material";
import { getStatusCamelName } from "../../../helpers/constants";
import { SupplierViewComponent } from "../../../components/subadmin/suppliers-manager/SupplierViewComponent";
import {
    useGetProductsBySuppliersQuery,
    useGetProductsQuery,
} from "../../../store/products/productsApis";
import SuppliersProducts from "../../../components/subadmin/products/SuppliersProducts";
import AutoCompleteServerSide from "../../../components/Common/AutoCompleteServerSide";
import AutoCompleteServerSideSingle from "../../../components/Common/AutoCompleteServerSideSingle";

const SupplierProductsContainer = (props) => {
    const { params, pagination, getSuppliers } = props;
    const status = params?.status;
    const [supplierId, setSupplierId] = useState(
        params?.supplier_id !== "add-product" ? params?.supplier_id : null
    );
    const [selectedSuppliers, setSelectedSuppliers] = useState(null);

    const {
        data: supplier,
        isFetching,
        isLoading,
        refetch: refetchSupplier,
    } = useGetSupplierByIdQuery(
        { unique_id: supplierId },
        { skip: !supplierId }
    );
    const {
        data: products,
        isFetching: fetchingProduct,
        isLoading: loadingProduct,
        refetch: refetchProducts,
    } = useGetProductsBySuppliersQuery(
        { supplier_id: supplierId },
        { skip: !supplierId }
    );
    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [params]);
    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    };

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="inherit"
            href="/"
            onClick={(e) => {
                e.preventDefault();
                props.navigate(`/products/${status}`);
            }}>
            {getStatusCamelName[status]} Products
        </Link>,
        <Typography key="3" color="primary">
            {params?.supplier_id === "add-product"
                ? "Add Products"
                : supplier?.user?.name}
        </Typography>,
    ];

    const handleFetchSuppliers = async (val) => {
        const res = await getSuppliers(val);

        const filteringData = res?.data?.results.map((item) => ({
            id: item.id,
            name: item.user.name,
            count: item.productCount,
            unique_id: item.unique_id,
        }));
        return { data: { result: filteringData } };
    };

    const handleSupplierSelect = (val) => {
        setSupplierId(val?.unique_id);
        setSelectedSuppliers(val);
    };

    return (
        <>
            {isLoading || loadingProduct || isFetching || fetchingProduct ? (
                <DashboardSkeleton />
            ) : (
                <Stack spacing={2}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12}>
                            <Breadcrumbs
                                separator={<NavigateNext fontSize="small" />}
                                aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    {params?.supplier_id === "add-product" ? (
                        <Grid container gap={2}>
                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        color: "#071B2A",
                                        fontWeight: "400",
                                    }}
                                    elevation={0}>
                                    <Grid item xs={12} sm={4} md={6} lg={4}>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "6px",
                                            }}>
                                            <InputLabel
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "#454545",
                                                    fontWeight: "500",
                                                }}
                                                shrink={false}>
                                                Supplier
                                            </InputLabel>
                                            <AutoCompleteServerSideSingle
                                                name="supplier_id"
                                                id="supplier_id"
                                                fullWidth
                                                fetchDataFunction={(d) =>
                                                    handleFetchSuppliers(d)
                                                }
                                                onChange={(val) =>
                                                    handleSupplierSelect(val)
                                                }
                                                defaultValue={selectedSuppliers}
                                                apiParams={{
                                                    status: "approved",
                                                }}
                                                extraTextKey="count"
                                            />
                                        </Box>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    ) : null}
                    {supplierId && (
                        <Fragment>
                            <SupplierViewComponent
                                formType="view"
                                supplierData={supplier}
                                status={
                                    params?.supplier_id === "add-product"
                                        ? "add-product"
                                        : status
                                }
                            />

                            <SuppliersProducts
                                navigate={props.navigate}
                                pagination={pagination}
                                handlePagination={handlePagination}
                                count={products?.total}
                                products={products?.results}
                                status={
                                    params?.supplier_id === "add-product"
                                        ? "add-product"
                                        : status
                                }
                                supplierId={supplierId}
                            />
                        </Fragment>
                    )}
                </Stack>
            )}
        </>
    );
};

export default SupplierProductsContainer;
