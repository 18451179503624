import React, { Fragment, useState } from "react";
import {
    TableCell,
    TableRow,
    Typography,
    Stack,
    Switch,
    Button,
    Checkbox,
} from "@mui/material";
import TableCommon from "../../Common/Table";
import { handleDateTimeDefault } from "../../../helpers/utils";
import VerticalMenu from "../../Common/VerticleAction";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import { PencilIcon } from "../../Common/Icons";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReasonDialog from "../../Common/ReasonDialog";
import ReplayIcon from '@mui/icons-material/Replay';
import { useUpdateStatusMutation } from "../../../store/suppliers/suppliersApis";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";

const SuppliersTableComponent = ({ loading, fetching = false, count, data, edit, pagination, handlePagination, status, setSelectedRows, selectedRows }) => {
    const [handleUpdate] = useUpdateStatusMutation();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [rejectPopupOpen, setRejectPopupOpen] = useState(false);
    const [statusToHandle, setStatusToHandle] = useState(null);
    const [idToHandle, setIdToHandle] = useState(null);


    const handleApprove = (row, status) => {
        setStatusToHandle(status);
        setIdToHandle(row?.id);
        setConfirmationOpen(true);
    };

    const handleConfirmApprove = () => {
        let payload = {};

        if (statusToHandle === "reconsider") {
            payload = {
                id: idToHandle,
                status: status === "suspended" ? "approved" : "pending"
            }
        } else if (statusToHandle === "approved") {
            payload = {
                id: idToHandle,
                status: "approved"
            }
        }
        handleUpdate(payload);
        setConfirmationOpen(false);
        setStatusToHandle(null);
        setIdToHandle(null);
    };

    const handleCloseApprove = () => {
        setConfirmationOpen(false);
    };

    const handleReject = (row, status) => {
        setStatusToHandle(status);
        setIdToHandle(row?.id);
        setRejectPopupOpen(true);
    };

    const handleCloseRejectPopup = (reason) => {
        let payload = {
            id: idToHandle,
            status: statusToHandle,
            reason: reason
        };

        handleUpdate(payload);
        setStatusToHandle(null);
        setIdToHandle(null);
        setRejectPopupOpen(false);
    };

    let columns = [
        {
            title: "Supplier Id",
            sort: false,
            width: "10%",
        },
        {
            title: "Registered On",
            field: "createdAt",
            sort: true,
            width: "20%",
        },
        {
            title: "User",
            field: "user",
            sort: false,
            width: "20%",
        },
        {
            title: "Business Name",
            field: "legal_name",
            sort: true,
            width: "30%",
        },
        {
            title: "Type",
            field: "business_type",
            sort: true,
            width: "30%",
        },
        {
            title: "",
            name: "",
            sort: false,
            width: "10%",
        },
    ];

    const renderAction = (row) => {
        switch (status) {
            case "pending":
                return (
                    <VerticalMenu>
                        <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => handleApprove(row, "approved")}>
                            <CheckCircleOutlineIcon color="success" /> Approve
                        </Typography>
                        <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => handleReject(row, "rejected")}>
                            <HighlightOffIcon color="error" /> Reject
                        </Typography>
                    </VerticalMenu>
                );
            case "rejected":
            case "suspended":
                return (
                    <VerticalMenu>
                        <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => handleApprove(row, "reconsider")}>
                            <ReplayIcon /> Reconsider
                        </Typography>
                    </VerticalMenu>
                );
            case "approved":
                return (
                    <VerticalMenu>
                        <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => handleReject(row, "suspended")}>
                            <NotInterestedIcon /> Suspend
                        </Typography>
                    </VerticalMenu>
                );
            default:
                return (
                    <VerticalMenu>
                        <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => edit(row)}>
                            <Add /> Add
                        </Typography>
                    </VerticalMenu>
                );
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelectedRows(newSelected);
            return;
        }
        setSelectedRows([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }
        setSelectedRows(newSelected);
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" }, ...(row?.disabled && { opacity: 0.4 }) }}
                >
                    {/* <TableCell padding="checkbox" sx={{ p: "5px !important" }}>
                        <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </TableCell> */}
                    <TableCell>
                        <Link to="/" style={{ textDecoration: "none" }} onClick={(e) => { e.preventDefault(); edit(row, 'view'); }}>{row.unique_id || "S0112312312"}</Link>
                    </TableCell>
                    <TableCell>
                        {handleDateTimeDefault(row?.createdAt, "dd MMM yyyy")}
                    </TableCell>
                    <TableCell>
                        {row?.user?.name}
                    </TableCell>
                    <TableCell>
                        {row?.legal_name}
                    </TableCell>
                    <TableCell>
                        {row?.business_type}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {renderAction(row)}
                    </TableCell>
                </TableRow>
            );
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={5} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <>
            <TableCommon
                columns={columns}
                tableData={renderTableData}
                count={count}
                loading={loading || fetching}
                pagination={pagination}
                handlePagination={handlePagination}
            />

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleCloseApprove}
                onConfirm={handleConfirmApprove}
                title={
                    statusToHandle === "reconsider" ?
                        "Are you sure you want to reconsider?" : "All the details are correct"
                }
                subtitle={
                    statusToHandle === "reconsider" ? null :
                        "Make sure all the details are correct."
                }
                confirmButtonTitle={
                    "Yes, Sure"
                }
            />
            {/* Reject Dialog */}
            <ReasonDialog
                open={rejectPopupOpen}
                onClose={handleCloseRejectPopup}
                onConfirm={handleCloseRejectPopup}
                title={
                    statusToHandle === "rejected" ?
                        "Reason to reject" :
                        statusToHandle === "suspended" ?
                            "Reason to suspend" :
                            null
                }
            />
        </>
    );
};

export default SuppliersTableComponent;
