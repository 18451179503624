import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from "@mui/material"
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SingleLevelMenu } from "./SingleLevelMenu";
import { drawerWidth } from "../../helpers/constants";

const groupItemsByPlaceholder = (items) => {
    return items.reduce((acc, item) => {
        const key = item.placeholder || "NO_PLACEHOLDER";
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});
};


export const MultiLevelMenu = ({ key, module, path_url, theme, navigate }) => {
    const { items } = module;
    const groupedItems = groupItemsByPlaceholder(items);
    const [open, setOpen] = useState(Boolean(items.find((obj) => obj.link === path_url)) || false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const isSelected = path_url === module.link || (path_url === '/' && module.link === '/dashboard');

    return (
        <Tooltip
            key={key}
            placement='right'
            title={module.title}
        >
            <ListItemButton
                button={"true"}
                key={module.id}
                onClick={handleClick}
                selected={isSelected}
                sx={{
                    my: 0.5,
                    gap: "10px",
                    borderRadius: "10px",
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: "35px",
                        ...(isSelected ? {
                            color: theme.palette.primary.main,
                        } : null),
                        ...(theme.palette.mode === "dark" &&
                            module.title === "Connections"
                            ? { filter: "invert(1)" }
                            : ""),
                    }}
                >
                    {module.logo(isSelected)}
                </ListItemIcon>
                <ListItemText
                    primary={module.title}
                    sx={{
                        ...(path_url ===
                            module.link || (path_url === "/" && module.link === "/dashboard") ? {
                            color: theme.palette.primary.main,
                        } : null),
                    }}
                />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {Object.keys(groupedItems).map((key) => (
                    <div key={key}>
                        {key !== "NO_PLACEHOLDER" && <Typography variant="subtitle2" sx={{ ml: 2 }}>{key}</Typography>}
                        <List component="div" disablePadding >
                            {groupedItems[key].map((child, childKey) => (
                                <SingleLevelMenu
                                    key={("module_", childKey)}
                                    module={child}
                                    onClick={() => navigate(child.link)}
                                    path_url={path_url}
                                    theme={theme}
                                    haveParent={true}
                                />
                            ))}
                        </List>
                    </div>
                ))}
                {/* {items.map((child, key) => {
                    const findplaceHolder = items.find((obj) => obj.link === path_url);
                    return (
                        <>
                            <SingleLevelMenu
                                key={("module_", key)}
                                module={child}
                                onClick={() => navigate(child.link)}
                                path_url={path_url}
                                theme={theme}
                                haveParent={true}
                            />
                        </>
                    )
                })} */}
            </Collapse>
        </Tooltip >
    )
}