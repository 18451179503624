import React from "react";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";

const WelcomeImage = () => {
    return (
        <Stack
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{
                height: { xs: "auto", sm: "20vh", md: "100vh", lg: "100vh" },
                width: "auto",
                backgroundColor: "background.default",
                borderRightWidth: "2px",
                borderRightStyle: "solid",
                borderRightColor: "#D6D6D6",
                display: "flex",
                flexDirection: { xs: "row", sm: "row", md: "column", lg: "column" },
            }}
        >
            <Box sx={{ height: { xs: "auto", md: "50%" }, width: "100%", flexDirection: "column", display: "flex", alignItems: "flex-start", justifyContent: "center", gap: "32px", paddingLeft: "84px" }}>
                <img
                    src="assets/images/logo.png"
                    alt="paquej-logo"
                    style={{ width: "211px" }}
                />
                <Typography variant="h6" style={{ color: "#575757", fontSize: "24px" }}>Sourcing Platform for Packaging Industry</Typography>
            </Box>
            <img src="assets/images/login-bg.png" alt="login bg" style={{ width: "100%", height: "50%", display: { xs: "none", md: "block" } }} />
        </Stack>
    );
};

export default WelcomeImage;
