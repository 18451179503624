import React from "react";
import { useDispatch } from "react-redux";

// mui components
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// redux store
import { set_dark_mode } from "./store/common/commonSlice";

// project imports
import SnackBarContainer from "./components/Common/Snackbar";
import LightTheme from "./helpers/theme/LightTheme";
import NavigationScroll from "./components/layout/NavigationScroll";

// routing
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";

// ==============================|| APP Component ||============================== //

function App() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(set_dark_mode("light"));
    }, [dispatch]);

    const theme = React.useMemo(
        () => createTheme(LightTheme),
        []
    );

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackBarContainer />
                <BrowserRouter>
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

export default App;