import React from "react";

import BaseLayout from "../../components/layout/BaseLayout";
import AdminRoutes from "../../routes/AdminRoutes";

import { withTheme } from "@emotion/react";
import {
    CertifiedIcon,
    DashboardIcon,
    PackageIcon,
    SupplierIcon,
} from "../../components/Common/Icons";
import HomePageCategory from "../../pages/admin/HomePageCategory";
class LayoutContainer extends React.Component {
    render() {
        return (
            <BaseLayout
                params={this.props.params}
                modules={adminModules}
                ChildComponent={AdminRoutes}
            />
        );
    }
}
export default withTheme(LayoutContainer);

const adminModules = [
    {
        id: "dashboard",
        title: "Dashboard",
        link: "/dashboard",
        role: ["admin"],
        logo: (active) => [<DashboardIcon active={active} />],
    },
    {
        id: "home",
        title: "Home",
        link: "/home",
        role: ["admin"],
        logo: (active) => [<DashboardIcon active={active} />],
    },
    {
        id: "customers",
        title: "Customers",
        role: ["admin"],
        logo: (active) => [<PackageIcon active={active} />],
        placeholder: ["SUPPLIER", "BUYER"],
        items: [
            {
                id: "supplier-new",
                title: "New",
                role: ["admin"],
                link: "/supplier/new",
                placeholder: "SUPPLIER",
            },
            {
                id: "supplier-active",
                title: "Active",
                role: ["admin"],
                link: "/supplier/active",
                placeholder: "SUPPLIER",
            },
            {
                id: "supplier-profile",
                title: "Profile",
                role: ["admin"],
                link: "/supplier/profile",
                placeholder: "SUPPLIER",
            },
            {
                id: "supplier-rejected",
                title: "Rejected",
                role: ["admin"],
                link: "/supplier/rejected",
                placeholder: "SUPPLIER",
            },
            {
                id: "supplier-suspended",
                title: "Suspended",
                role: ["admin"],
                link: "/supplier/suspended",
                placeholder: "SUPPLIER",
            },
            {
                id: "buyer-new",
                title: "New",
                role: ["admin"],
                link: "/buyer/new",
                placeholder: "BUYER",
            },
            {
                id: "buyer-active",
                title: "Active",
                role: ["admin"],
                link: "/buyer/active",
                placeholder: "BUYER",
            },
            {
                id: "buyer-rejected",
                title: "Rejected",
                role: ["admin"],
                link: "/buyer/rejected",
                placeholder: "BUYER",
            },
            {
                id: "buyer-suspended",
                title: "Suspended",
                role: ["admin"],
                link: "/buyer/suspended",
                placeholder: "BUYER",
            },
        ],
    },
    {
        id: "Homepage-Products",
        title: "Display",
        link: "/homepagecategory",
        role: ["admin"],
        logo: (active) => [<DashboardIcon active={active} />],
    },
    {
        id: "products-crm",
        title: "Supplier Products",
        role: ["admin"],
        logo: (active) => <PackageIcon active={active} />,
        items: [
            {
                id: "products-pending",
                title: "Pending",
                role: ["admin"],
                link: "/products/pending",
            },
            {
                id: "products-active",
                title: "Active",
                role: ["admin"],
                link: "/products/active",
            },
            {
                id: "products-disabled",
                title: "Disabled",
                role: ["admin"],
                link: "/products/disabled",
            },
        ],
    },
    {
        id: "products",
        title: "Master Products",
        role: ["admin"],
        logo: (active) => <PackageIcon active={active} />,
        items: [
            {
                id: "icategory",
                title: "iCategory",
                role: ["admin"],
                link: "/icategory",
            },
            {
                id: "category",
                title: "Category",
                role: ["admin"],
                link: "/category",
            },
            {
                id: "product-types",
                title: "Product Types",
                role: ["admin"],
                link: "/product-types",
            },
            {
                id: "product-image",
                title: "Product Image",
                role: ["admin"],
                link: "/product-image",
            },
        ],
    },
    {
        id: "supplier-types",
        title: "Supplier Types",
        link: "/supplier-types",
        role: ["admin"],
        logo: (active) => <SupplierIcon active={active} />,
    },
    {
        id: "certified",
        title: "Certified By",
        link: "/certified",
        role: ["admin"],
        logo: (active) => <CertifiedIcon active={active} />,
    },
];
