import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeInitialState, handlePaginationState } from "../../../store/suppliers/suppliersSlice";

const mapStateToProps = (state) => {
    return {
        // location
        formType: state.suppliers.formType,
        initialValues: state.suppliers.initialValues,
        showDrawer: state.suppliers.showDrawer,
        pagination: state.suppliers.pagination,
    };
};

const mapDispatch = {
    changeInitialState: changeInitialState,
    handlePaginationState: handlePaginationState,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;