import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
} from "@mui/material";
import { WarningIcon } from "./Icons";
import { CloseOutlined } from "@mui/icons-material";
import Input from "./Input";

const ReasonDialog = ({ open, onClose, onConfirm, title, buttonTitle = "Reject" }) => {
    const [reason, setReason] = useState("");
    const [error, setError] = useState(false);

    const handleConfirm = () => {
        if (!reason) {
            setError("Reason is required");
            return;
        }
        onConfirm(reason);
        setReason("");
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" sx={{ textAlign: "center" }}>
            <CloseOutlined onClick={onClose} sx={{ position: "absolute", right: 15, top: 15, cursor: "pointer" }} />
            <DialogContent sx={{ textAlign: "center", px: 5, py: 3 }}>
                <WarningIcon />
                <Typography variant="h6">{title}</Typography>
                <Grid container sx={{ minWidth: '400px', textAlign: "left", paddingTop: 2 }}>
                    <Grid item xs={12} md={12}>
                        <Input
                            label="Reason"
                            value={reason || ""}
                            fullWidth
                            multiline
                            rows={6}
                            placeholder="Enter reason"
                            onChange={(e) => setReason(e.target.value)}
                            error={Boolean(error)}
                            helperText={error}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", paddingBottom: 3 }}>
                <Button onClick={() => handleConfirm()} color="primary" variant="contained">
                    {buttonTitle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReasonDialog;
