import React, { useEffect } from "react";
import { Button, Grid, Paper, Stack, Typography } from "@mui/material";

import CategoryTableComponent from "../../../components/admin/category-manager/CategoryTable";
import { CategoryAddDrawer } from "../../../components/admin/category-manager/CategoryAddDrawer";

import { useGetCategoryListQuery } from "../../../store/masters/mastersApis";
import { defaultPaginate } from "../../../helpers/constants";
import { AddOutlined } from "@mui/icons-material";

const CategoryManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination } = props;
    const {
        isLoading,
        data: category,
        isFetching,
    } = useGetCategoryListQuery(pagination);

    const handleAddCategory = () => {
        props.changeCategoryInitialState({
            showDrawer: true,
            formType: "Add",
            initialValues: null,
        });
    };

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, []);

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
    };

    const handleDrawerClose = () => {
        props.changeCategoryInitialState({
            showDrawer: false,
            formType: "",
            initialValues: null,
        });
    };

    const handleCategoryEdit = (data, type) => {
        let payload = {
            id: data.id,
            name: data.name,
            icategoryId: data.icategoryId,
            homepagedisplay: data?.homepagedisplay,
            priority: data?.priority,
        };
        props.changeCategoryInitialState({
            showDrawer: true,
            formType: type,
            initialValues: payload,
        });
    };

    return (
        <Stack spacing={2}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6">
                        Category{" "}
                        <span
                            style={{
                                color: "gray",
                                fontSize: "10px",
                                verticalAlign: "super",
                            }}>
                            ({category?.total || 0})
                        </span>{" "}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{ alignSelf: "center" }}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}>
                    <Button
                        disableElevation
                        variant="contained"
                        sx={{
                            borderRadius: "8px",
                            marginLeft: 2,
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            fontSize: "16px",
                        }}
                        onClick={() => handleAddCategory()}>
                        <AddOutlined />
                        New Category
                    </Button>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}>
                        <CategoryTableComponent
                            edit={(val, type) => handleCategoryEdit(val, type)}
                            loading={isLoading}
                            fetching={isFetching}
                            count={category?.total}
                            data={category?.results || []}
                            pagination={pagination}
                            handlePagination={(val) => handlePagination(val)}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <CategoryAddDrawer
                show={showDrawer}
                close={handleDrawerClose}
                formType={formType}
                initialValues={initialValues}
            />
        </Stack>
    );
};

export default CategoryManagerContainer;
