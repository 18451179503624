import React from "react";
import { TableCell, TableRow, Checkbox } from "@mui/material";
import TableCommon from "../../Common/Table";
import { Link } from "react-router-dom";

const ProductsTableComponent = ({
    loading,
    fetching = false,
    count,
    data,
    edit,
    pagination,
    handlePagination,
    status,
    setSelectedRows,
    selectedRows,
}) => {
    let columns = [
        {
            title: "Product Id",
            sort: false,
            width: "10%",
        },
        {
            title: "Product Type",
            field: "product_name",
            sort: true,
            width: "30%",
        },
        {
            title: "Category",
            field: "Category_name",
            sort: true,
            width: "30%",
        },
        {
            title: "User",
            field: "supplier",
            sort: false,
            width: "20%",
        },
        {
            title: "Business Name",
            field: "legal_name",
            sort: true,
            width: "30%",
        },
    ];

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelectedRows(newSelected);
            return;
        }
        setSelectedRows([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        setSelectedRows(newSelected);
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const renderTableData =
        !loading && data && data.length > 0 ? (
            data.map(function (row, index) {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                console.log(row);
                return (
                    <TableRow
                        key={index}
                        sx={{
                            "td, th": { border: 0, padding: "10px" },
                            ...(row?.disabled && { opacity: 0.4 }),
                        }}>
                        <TableCell
                            padding="checkbox"
                            sx={{ p: "5px !important" }}>
                            <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onClick={(event) => handleClick(event, row.id)}
                                inputProps={{
                                    "aria-labelledby": labelId,
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <Link
                                to="/"
                                style={{ textDecoration: "none" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    edit(row, "drawer");
                                }}>
                                {row.unique_id}
                            </Link>
                        </TableCell>
                        <TableCell>{row?.product_name}</TableCell>
                        <TableCell>{row?.Category_name}</TableCell>
                        <TableCell>
                            <Link
                                to="/"
                                style={{ textDecoration: "none" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    edit(
                                        { unique_id: row?.supplier?.unique_id },
                                        "view"
                                    );
                                }}>
                                {row?.user?.name}
                            </Link>
                        </TableCell>
                        <TableCell>{row?.legal_name}</TableCell>
                    </TableRow>
                );
            })
        ) : (
            <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
                <TableCell colSpan={5} align="center">
                    Data not found
                </TableCell>
            </TableRow>
        );

    return (
        <TableCommon
            columns={columns}
            tableData={renderTableData}
            count={count}
            loading={loading || fetching}
            pagination={pagination}
            handlePagination={handlePagination}
            handleSelectAllClick={handleSelectAllClick}
            numSelected={selectedRows.length}
            checkbox={true}
        />
    );
};

export default ProductsTableComponent;
