import React, { useEffect } from "react";
import { Box, Button, Card, CardContent, Grid, Paper, Stack, Typography } from "@mui/material";

import ProductTypesTableComponent from "../../../components/admin/product-types/ProductTypesTable";
import { ProductTypesAddDrawer } from "../../../components/admin/product-types/ProductTypesAddDrawer";

import { useGetProductTypesListQuery } from "../../../store/masters/mastersApis";
import { defaultPaginate } from "../../../helpers/constants";
import { AddOutlined } from "@mui/icons-material";
import { SearchRecords } from "../../../components/Common/SearchRecords";
import FilterTabs from "../../../components/Common/FilterTabs";

const ProductTypesManagerContainer = (props) => {
    const { showDrawer, formType, initialValues, pagination } = props;
    const { isLoading, data: category, isFetching } = useGetProductTypesListQuery(pagination);
    const [selectedRows, setSelectedRows] = React.useState([]);

    const handleAddProductTypes = () => {
        props.changeProductTypesInitialState({
            showDrawer: true,
            formType: "Add",
            initialValues: null
        })
    }

    useEffect(() => {
        props.handlePaginationState(defaultPaginate);
    }, [])

    const handlePagination = (setting) => {
        props.handlePaginationState(setting);
        setSelectedRows([]);
    }

    const handleDrawerClose = () => {
        props.changeProductTypesInitialState({ showDrawer: false, formType: "", initialValues: null })
    }

    const handleProductTypesEdit = (data, type) => {
        let payload = {
            id: data.id,
            name: data.name,
            content: data.content,
            categoryId: data.categoryId
        };
        props.changeProductTypesInitialState({ showDrawer: true, formType: type, initialValues: payload })
    }

    return (
        <Stack spacing={2}>
            <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6" >Product Types <span style={{ color: "gray", fontSize: "10px", verticalAlign: "super" }}>({category?.total || 0})</span> </Typography>
                </Grid>
                <Grid item xs={6} sx={{ alignSelf: "center" }} display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                    <SearchRecords handlePagination={handlePagination} pagination={pagination} placeholder={"Search Product Type"} />
                    <Button
                        disableElevation
                        variant="contained"
                        sx={{ borderRadius: "8px", marginLeft: 2, display: "flex", alignItems: "center", gap: 1, fontSize: "16px" }}
                        onClick={() => handleAddProductTypes()}
                    >
                        <AddOutlined />
                        New Product
                    </Button>
                </Grid>
            </Grid>

            <Grid container sx={{ width: "100%" }}>
                <Grid item xs={4} sx={{ paddingRight: 2 }}>
                    <Card>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.all || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                All Product Types
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} display={"flex"} flexDirection={"row"} gap={0.2} sx={{ paddingRight: 1 }}>
                    <Card sx={{ flex: 1 }}>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.active || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                Active
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ flex: 1 }}>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.disabled || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                Disabled
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4} display={"flex"} flexDirection={"row"} gap={0.2} sx={{ paddingLeft: 1 }}>
                    <Card sx={{ flex: 1 }}>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.with_content || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                With Content
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ flex: 1 }}>
                        <CardContent sx={{ paddingTop: 3, px: 3 }}>
                            <Typography variant="h5" fontWeight={"600"} color="textPrimary">
                                {category?.stats?.without_content || 0}
                            </Typography>
                            <Typography
                                color="secondary.text"
                            >
                                Without Content
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <FilterTabs handlePagination={handlePagination} pagination={pagination} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />

            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: 2, color: "#071B2A", fontWeight: "400" }}
                        elevation={0}
                    >
                        {
                            category?.total === 0 ?
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ height: "300px" }}><Typography variant="subtitle" fontWeight={"600"} color="textSecondary">Nothing to display</Typography></Box>
                                :
                                <ProductTypesTableComponent selectedRows={selectedRows} setSelectedRows={setSelectedRows} edit={(val, type) => handleProductTypesEdit(val, type)} loading={isLoading} fetching={isFetching} count={category?.total} data={category?.results || []} pagination={pagination} handlePagination={(val) => handlePagination(val)} />
                        }
                    </Paper>
                </Grid>
            </Grid>
            <ProductTypesAddDrawer show={showDrawer} close={handleDrawerClose} formType={formType} initialValues={initialValues} />
        </Stack >
    );
}

export default ProductTypesManagerContainer;