import React, { useState } from "react";
import {
    TableCell,
    TableRow,
    Typography,
    Stack,
    Switch,
    Button,
    Checkbox,
    Box,
} from "@mui/material";
import TableCommon from "../../Common/Table";
import { handleDateTimeDefault } from "../../../helpers/utils";
import { useUpdateProductTypesMutation } from "../../../store/masters/mastersApis";
import VerticalMenu from "../../Common/VerticleAction";
import ConfirmationDialog from "../../Common/ConfirmationDialog";
import { ContentIcon, PencilIcon } from "../../Common/Icons";
import CustomizedSwitches from "../../Common/CustomSwitch";
import DescriptionIcon from '@mui/icons-material/Description';

const ProductTypesTableComponent = ({ loading, fetching = false, count, data, edit, pagination, handlePagination, setSelectedRows, selectedRows }) => {
    const [handleUpdate] = useUpdateProductTypesMutation();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [categoryIdToDelete, setProductTypesIdToDelete] = useState(null);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelectedRows(newSelected);
            return;
        }
        setSelectedRows([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }
        setSelectedRows(newSelected);
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const handleDisable = (row) => {
        setProductTypesIdToDelete(row);
        setConfirmationOpen(true);
    };

    const handleConfirmDisable = () => {
        handleUpdate({
            id: categoryIdToDelete?.id,
            name: categoryIdToDelete?.name,
            categoryId: categoryIdToDelete?.categoryId,
            disabled: !categoryIdToDelete?.disabled
        });
        setConfirmationOpen(false);
    };

    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);
    };

    let columns = [
        {
            title: "ID No.",
            sort: false,
            width: "10%",
        },
        {
            title: "ProductTypes",
            field: "name",
            sort: true,
            width: "30%",
        },
        {
            title: "Category",
            field: "category",
            sort: false,
            width: "30%",
        },
        {
            title: "Created by & date",
            field: "createdAt",
            sort: true,
            width: "20%",
        },
        {
            title: "",
            name: "",
            sort: false,
            width: "10%",
        },
    ];

    const renderTableData = !loading && data && data.length > 0 ? (
        data.map(function (row, index) {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
                <TableRow
                    key={index}
                    sx={{ "td, th": { border: 0, padding: "10px" }, ...(row?.disabled && { opacity: 0.4 }) }}
                >
                    <TableCell padding="checkbox" sx={{ p: "5px !important" }}>
                        <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center", }}>
                            {row?.content ? <DescriptionIcon sx={{ mr: 1 }} color="action" /> : null}
                            <Typography variant="body2">{row?.name}</Typography>
                            {row?.disabled ? <Button sx={{ ml: 2 }} disabled size="small" variant="contained">Disabled</Button> : null}
                        </Box>
                    </TableCell>
                    <TableCell>
                        {row?.category?.name}
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" fontFamily={500}>{row?.createdBy?.name}</Typography>
                        <Typography variant="caption">{handleDateTimeDefault(row?.createdAt)}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <VerticalMenu>
                            <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => edit(row, "Edit")}>
                                <PencilIcon /> Update
                            </Typography>
                            <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" onClick={() => edit(row, "Content")}>
                                <ContentIcon /> Content
                            </Typography>
                            <Stack direction="row" width={"100%"} justifyContent={"space-between"}>
                                <Typography sx={{ gap: 2, display: "flex", alignItems: "center" }} variant="body2" >
                                    {row?.disabled ? "Enable" : "Disable"}
                                </Typography>
                                <CustomizedSwitches checked={confirmationOpen} onChange={() => handleDisable(row)} />
                            </Stack>
                        </VerticalMenu>
                    </TableCell>
                </TableRow>
            );
        })
    ) : (
        <TableRow sx={{ "td, th": { border: 0, padding: "10px" } }}>
            <TableCell colSpan={5} align="center">Data not found</TableCell>
        </TableRow>
    );

    return (
        <>
            <TableCommon
                columns={columns}
                tableData={renderTableData}
                count={count}
                loading={loading || fetching}
                pagination={pagination}
                handlePagination={handlePagination}
                checkbox={true}
                numSelected={selectedRows.length}
                onSelectAllClick={handleSelectAllClick}
            />

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmationOpen}
                onClose={handleCloseConfirmation}
                onConfirm={handleConfirmDisable}
                title={categoryIdToDelete?.disabled ? "Are you sure you want to enable selected row?" : "Are you sure you want to disable selected row?"}
                confirmButtonTitle={categoryIdToDelete?.disabled ? "Yes, Enable" : "Yes, Disable"}
            />
        </>
    );
};

export default ProductTypesTableComponent;
