import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeMastersInitialStateICategory, handlePaginationStateICategory } from "../../../store/masters/mastersSlice";

const mapStateToProps = (state) => {
    return {
        // location
        formType: state.masters.formTypeICategory,
        initialValues: state.masters.initialValuesICategory,
        showDrawer: state.masters.showDrawerICategory,
        pagination: state.masters.paginationICategory,
    };
};

const mapDispatch = {
    changeICategoryInitialState: changeMastersInitialStateICategory,
    handlePaginationState: handlePaginationStateICategory,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(mapDispatch, dispatch);

const Store = (Container) =>
    connect(mapStateToProps, mapDispatchToProps)(Container);

export default Store;